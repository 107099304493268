define("additive-content/components/ac-contents/info-breadcrumbs", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _service, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="info-breadcrumbs font-sm {{this.classNames}} {{this._fontSizeClass}}" ...attributes>
    {{#each this.routeHierarchy as |route index|}}
      {{#if index}}
        <span class="secondary">
          &nbsp;&nbsp;/&nbsp;&nbsp;
        </span>
      {{/if}}
      <span class="secondary">
        {{route.name}}
      </span>
    {{/each}}
  </div>
  */
  {
    "id": "SSQEbOMx",
    "block": "[[[11,0],[16,0,[29,[\"info-breadcrumbs font-sm \",[30,0,[\"classNames\"]],\" \",[30,0,[\"_fontSizeClass\"]]]]],[17,1],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"routeHierarchy\"]]],null]],null],null,[[[41,[30,3],[[[1,\"      \"],[10,1],[14,0,\"secondary\"],[12],[1,\"\\n          /  \\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[10,1],[14,0,\"secondary\"],[12],[1,\"\\n      \"],[1,[30,2,[\"name\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[2,3]],null],[13]],[\"&attrs\",\"route\",\"index\"],false,[\"each\",\"-track-array\",\"if\"]]",
    "moduleName": "additive-content/components/ac-contents/info-breadcrumbs.hbs",
    "isStrictMode": false
  });
  let AcContentsInfoBreadcrumbsComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string), (_class = class AcContentsInfoBreadcrumbsComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      /**
       * Class names
       * @argument classNames
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "classNames", _descriptor3, this);
      /**
       * Specifies the font size of the breadcrumbs
       *
       * @argument size
       * @type {String}
       * @default 'sm'
       */
      _initializerDefineProperty(this, "size", _descriptor4, this);
    }
    /**
     * the hierarchy of routes to be displayed in the breadcrumbs
     *
     * @method readonly
     * @computed _routeHierarchy
     * @return {Array}
     * @public
     */
    get routeHierarchy() {
      let route = this.router.currentRouteName;
      if (!route) {
        return [];
      }
      let routes = [];
      /**
       * Save only second + third part of the path
       */
      let routeNames = route.split('.').slice(1, 3);

      /* capitalizes letter after the character "-" and removes "-"
       * Ex. portal-scores => portalScores
       */
      routeNames[1] = routeNames[1].replaceAll(/(-)(\S)/g, s => s.toUpperCase()).replaceAll('-', '');
      routes.unshift({
        name: this.intl.t(`routes.${routeNames.join('.')}.title`)
      });
      routes.unshift({
        name: this.intl.t(`routes.contents.title`)
      });
      return routes;
    }

    /**
     * returns class of the font size
     * @computed _fontSizeClass
     * @return {String}
     */
    get _fontSizeClass() {
      return `font-${this.size}`;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "classNames", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "size", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'sm';
    }
  })), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AcContentsInfoBreadcrumbsComponent);
});