define("additive-content/components/ac-contents/rates/season-room-dialog", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "@ember/object", "@ember/string", "ember-arg-types", "prop-types", "@glimmer/tracking", "ember-changeset-validations", "ember-changeset", "additive-content/validations/season"], function (_exports, _component, _templateFactory, _component2, _service, _object, _string, _emberArgTypes, _propTypes, _tracking, _emberChangesetValidations, _emberChangeset, _season) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <UiModal @title={{this.model.name}} @onClose={{this.close}} @size="sm" as |m|>
    <m.header />
    <m.content @classNames="pb0" as |c|>
      <c.body {{did-insert this.didInsert}} {{will-destroy this.willDestroy}}>
        {{#if this.changeset}}
          {{#each this.mealTypes as |mealType|}}
            <UiInput
              @label={{t (concat "contents.rates.rate.properties.mealTypes." mealType.intlKey)}}
              @value={{get this.changeset mealType.key}}
              @errorMessage={{get this.changeset.error (concat mealType.key ".validation")}}
              @isTouched={{this.isTouched}} as |input|
            >
              <input.right>
                {{currency-symbol this.uiAppSettings.currency}}
              </input.right>
            </UiInput>
          {{/each}}
        {{/if}}
      </c.body>
    </m.content>
    <m.footer>
      <UiButton @label={{t "global.actions.save"}} @isSecondary={{true}} @onClick={{this.save}} />
    </m.footer>
  </UiModal>
  
  <UiDiscardChanges @isOpen={{this.isDiscardChangesDialog}} @onDiscard={{this.discardChanges}} />
  */
  {
    "id": "V78VDA66",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@onClose\",\"@size\"],[[30,0,[\"model\",\"name\"]],[30,0,[\"close\"]],\"sm\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"header\"]],null,null,null],[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,[[\"@classNames\"],[\"pb0\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"body\"]],[[4,[38,1],[[30,0,[\"didInsert\"]]],null],[4,[38,2],[[30,0,[\"willDestroy\"]]],null]],null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"changeset\"]],[[[42,[28,[37,5],[[28,[37,5],[[30,0,[\"mealTypes\"]]],null]],null],null,[[[1,\"          \"],[8,[39,6],null,[[\"@label\",\"@value\",\"@errorMessage\",\"@isTouched\"],[[28,[37,7],[[28,[37,8],[\"contents.rates.rate.properties.mealTypes.\",[30,3,[\"intlKey\"]]],null]],null],[28,[37,9],[[30,0,[\"changeset\"]],[30,3,[\"key\"]]],null],[28,[37,9],[[30,0,[\"changeset\",\"error\"]],[28,[37,8],[[30,3,[\"key\"]],\".validation\"],null]],null],[30,0,[\"isTouched\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[30,4,[\"right\"]],null,null,[[\"default\"],[[[[1,\"\\n              \"],[1,[28,[35,10],[[30,0,[\"uiAppSettings\",\"currency\"]]],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n          \"]],[4]]]]],[1,\"\\n\"]],[3]],null]],[]],null],[1,\"    \"]],[]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n  \"],[8,[30,1,[\"footer\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,11],null,[[\"@label\",\"@isSecondary\",\"@onClick\"],[[28,[37,7],[\"global.actions.save\"],null],true,[30,0,[\"save\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\\n\"],[8,[39,12],null,[[\"@isOpen\",\"@onDiscard\"],[[30,0,[\"isDiscardChangesDialog\"]],[30,0,[\"discardChanges\"]]]],null]],[\"m\",\"c\",\"mealType\",\"input\"],false,[\"ui-modal\",\"did-insert\",\"will-destroy\",\"if\",\"each\",\"-track-array\",\"ui-input\",\"t\",\"concat\",\"get\",\"currency-symbol\",\"ui-button\",\"ui-discard-changes\"]]",
    "moduleName": "additive-content/components/ac-contents/rates/season-room-dialog.hbs",
    "isStrictMode": false
  });
  /**
   * enables editing of a season-room
   *
   * @class AcContentsSeasonRoomDialogComponent
   */
  let AcContentsSeasonRoomDialogComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.object), _dec3 = (0, _emberArgTypes.arg)(_propTypes.string), _dec4 = (0, _emberArgTypes.arg)(_propTypes.func), (_class = class AcContentsSeasonRoomDialogComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor2, this);
      _initializerDefineProperty(this, "uiToast", _descriptor3, this);
      _initializerDefineProperty(this, "store", _descriptor4, this);
      /**
       * the model that should be edited
       *
       * @argument model
       * @type {Object}
       */
      _initializerDefineProperty(this, "model", _descriptor5, this);
      /**
       * the rate model defining the mealTypes
       *
       * @argument rate
       * @type {Object}
       */
      _initializerDefineProperty(this, "rate", _descriptor6, this);
      /**
       * the id the of the parent offer
       *
       * @argument offerId
       * @type {String}
       */
      _initializerDefineProperty(this, "seasonId", _descriptor7, this);
      /**
       * the action that closes the dialog
       *
       * @argument onClose
       * @type {Function}
       */
      _initializerDefineProperty(this, "onClose", _descriptor8, this);
      /**
       * the internal changeset
       *
       * @property changeset
       * @type {Changeset}
       */
      _initializerDefineProperty(this, "changeset", _descriptor9, this);
      /**
       * whether the discard changes dialog is shown
       *
       * @property isDiscardChangesDialog
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "isDiscardChangesDialog", _descriptor10, this);
      /**
       * whether the form has been touched
       *
       * @property isTouched
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "isTouched", _descriptor11, this);
    }
    get mealTypes() {
      return this.rate.mealTypes.map(type => ({
        intlKey: type,
        key: `${(0, _string.camelize)(type)}Price`
      }));
    }
    didInsert() {
      const mealTypes = this.mealTypes.map(type => type.key);
      const validation = (0, _season.seasonRoomValidation)(this.intl, mealTypes);
      this.changeset = new _emberChangeset.default(this.model, (0, _emberChangesetValidations.default)(validation), validation);
    }
    willDestroy() {
      var _this$changeset;
      super.willDestroy(...arguments);
      (_this$changeset = this.changeset) === null || _this$changeset === void 0 || _this$changeset.destroy();
    }
    async save() {
      this.isTouched = true;
      await this.changeset.validate();
      if (!this.changeset.get('isValid')) return;
      try {
        await this.changeset.save({
          adapterOptions: {
            season: this.seasonId
          }
        });
        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });
        this.onClose();
      } catch (e) {
        this.uiToast.showToast({
          title: this.intl.t('toast.unexpectedError'),
          type: 'error'
        });
      }
    }
    close() {
      if (this.changeset.get('isDirty')) {
        this.isDiscardChangesDialog = true;
      } else {
        this.onClose();
      }
    }
    discardChanges() {
      var _this$model;
      this.changeset.rollback();
      (_this$model = this.model) === null || _this$model === void 0 || _this$model.rollbackAttributes();
      this.onClose();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "rate", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "seasonId", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "changeset", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isDiscardChangesDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isTouched", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "didInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "willDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "discardChanges", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "discardChanges"), _class.prototype)), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AcContentsSeasonRoomDialogComponent);
});