define("additive-content/controllers/instance/contents/events/index/event/edit", ["exports", "@ember/controller", "@glimmer/tracking", "@ember/object", "@ember/service", "date-fns"], function (_exports, _controller, _tracking, _object, _service, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let InstanceContentsEventsIndexEventEditController = _exports.default = (_class = class InstanceContentsEventsIndexEventEditController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "uiToast", _descriptor4, this);
      _initializerDefineProperty(this, "router", _descriptor5, this);
      _initializerDefineProperty(this, "store", _descriptor6, this);
      _defineProperty(this, "queryParams", ['language']);
      _initializerDefineProperty(this, "language", _descriptor7, this);
      _initializerDefineProperty(this, "validation", _descriptor8, this);
      _initializerDefineProperty(this, "isSaveDialog", _descriptor9, this);
      _initializerDefineProperty(this, "selectedSaveOption", _descriptor10, this);
    }
    get saveOptions() {
      return ['all', 'allFollowing', 'this'].map(opt => ({
        name: this.intl.t(`contents.events.saveDialog.${opt}`),
        value: opt
      }));
    }

    /**
     * get the current date of the event
     *
     * @computed date
     */
    get date() {
      if (this.model.date) {
        return (0, _dateFns.format)(this.model.date, 'yyyy-MM-dd');
      }
      return null;
    }
    get isViewer() {
      return this.currentUser.hasReadOnlyContents;
    }
    onChange(changeset, key, date) {
      date && changeset.set(key, new Date(date));
    }
    onTimeValidStateChange(changeset, key, isValid) {
      if (!isValid) {
        changeset.pushErrors(key, 'invalid');
      }
    }
    onSwitch(changeset, key, value) {
      changeset.set(key, value);
      changeset.execute();
    }

    /**
     * Open the save dialog in case the model has a rrule
     * otherwise save normally
     *
     * @function onSave
     * @param {Model} model
     */
    async onSave(model, changeset) {
      if (model.rrule) {
        changeset.execute();
        this.isSaveDialog = true;
        return;
      }
      try {
        const response = await changeset.save();
        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });
        this.transitionToRoute('instance.contents.events.index.event', response.id, {
          queryParams: {
            dateFrom: (0, _dateFns.format)((0, _dateFns.startOfMonth)(response.startsAt), 'yyyy-MM-dd'),
            dateUntil: (0, _dateFns.format)((0, _dateFns.endOfMonth)(response.startsAt), 'yyyy-MM-dd')
          }
        });
      } catch (e) {
        this.uiToast.showToast({
          title: this.intl.t('toast.unexpectedError'),
          type: 'error'
        });
      }
    }

    /**
     * action to save the content
     *
     * we have three cases:
     * - all events are edited PUT /events/id
     * - all following events PUT /events/id/from-date/id
     * - this event PUT /events/id/date/id
     *
     * @function saveContent
     */
    async saveContent() {
      let event;
      try {
        if (this.selectedSaveOption === 'all') {
          event = await this.model.save();
        } else {
          const adapter = this.store.adapterFor('event');
          const baseUrl = adapter.buildURL('event', this.model.id);
          let url;
          if (!this.date) {
            throw new Error();
          }
          if (this.selectedSaveOption === 'allFollowing') {
            url = `${baseUrl}/from-date/${this.date}`;
          } else if (this.selectedSaveOption === 'this') {
            url = `${baseUrl}/date/${this.date}`;
          }
          const response = await this.authenticatedFetch.fetch(url, {
            method: 'PUT'
          }, this.model.serialize());
          const json = await response.json();
          this.store.pushPayload('event', json);
          event = json.event;
        }
        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });
        this.closeSaveDialog();
        const queryParams = this.date && {
          dateFrom: (0, _dateFns.format)((0, _dateFns.startOfMonth)(new Date(this.date)), 'yyyy-MM-dd'),
          dateUntil: (0, _dateFns.format)((0, _dateFns.endOfMonth)(new Date(this.date)), 'yyyy-MM-dd')
        };
        this.transitionToRoute('instance.contents.events.index.event', event.id, {
          queryParams
        });
      } catch (e) {
        this.uiToast.showToast({
          title: this.intl.t('toast.unexpectedError'),
          type: 'error'
        });
      }
    }
    closeSaveDialog() {
      this.isSaveDialog = false;
      this.selectedSaveOption = 'all';
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "language", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "validation", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isSaveDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "selectedSaveOption", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'all';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onTimeValidStateChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onTimeValidStateChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSwitch", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onSwitch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSave", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onSave"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveContent", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveContent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeSaveDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeSaveDialog"), _class.prototype)), _class);
});