define("additive-content/controllers/instance/styles/style", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@glimmer/tracking", "ember-concurrency", "additive-content/utils/render-utils"], function (_exports, _controller, _object, _service, _tracking, _emberConcurrency, _renderUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let InstanceStylesStyleController = _exports.default = (_dec = (0, _emberConcurrency.task)(function* () {
    let styleBlocks = yield this.store.findAll('style-block', {
      adapterOptions: {
        style: this.model.id
      }
    });

    // hack to make sure only style blocks of the current style are loaded
    let hasDuplicates = false;
    const aggregator = [];
    styleBlocks.toArray().some(block => {
      if (aggregator.includes(`${block.blockIdentifier}-${block.variationIdentifier}`)) {
        hasDuplicates = true;
        return;
      }
      aggregator.push(`${block.blockIdentifier}-${block.variationIdentifier}`);
    });
    if (hasDuplicates) {
      this.store.unloadAll('style-block');
      styleBlocks = yield this.store.findAll('style-block', {
        adapterOptions: {
          style: this.model.id
        }
      });
    }
    this.styleBlocks = styleBlocks;

    // use logic from NL app even though we only show one block
    const blockGroups = [];
    [['button']].forEach(group => {
      const groupBlocks = [];
      group.forEach(block => {
        const blockVariant = this.styleBlocks.find(v => v.blockIdentifier === block);
        if (blockVariant) {
          groupBlocks.push(blockVariant);
        }
      });
      blockGroups.push(groupBlocks);
    });
    this.blockGroups = blockGroups;
    this.templateBlocks = _renderUtils.STYLE_PREVIEW_BLOCKS.map(block => {
      const [b, v] = block.split('.');
      return this.styleBlocks.filterBy('blockIdentifier', b).findBy('variationIdentifier', v);
    }).filter(b => b);
  }), (_class = class InstanceStylesStyleController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "aeSaveStack", _descriptor, this);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor2, this);
      _initializerDefineProperty(this, "currentUser", _descriptor3, this);
      _initializerDefineProperty(this, "intl", _descriptor4, this);
      _initializerDefineProperty(this, "router", _descriptor5, this);
      _initializerDefineProperty(this, "store", _descriptor6, this);
      _initializerDefineProperty(this, "uiCd", _descriptor7, this);
      _initializerDefineProperty(this, "uiToast", _descriptor8, this);
      _initializerDefineProperty(this, "showDiscardChanges", _descriptor9, this);
      _initializerDefineProperty(this, "isFullTemplate", _descriptor10, this);
      _initializerDefineProperty(this, "blockGroups", _descriptor11, this);
      _initializerDefineProperty(this, "styleBlocks", _descriptor12, this);
      _initializerDefineProperty(this, "templateBlocks", _descriptor13, this);
      _initializerDefineProperty(this, "fetchVariants", _descriptor14, this);
    }
    get isVariationSettings() {
      return this.router.isActive('instance.styles.style.block.variation');
    }
    get activeVariation() {
      var _this$router$currentR;
      return (_this$router$currentR = this.router.currentRoute.attributes) === null || _this$router$currentR === void 0 ? void 0 : _this$router$currentR.variation;
    }
    get _isViewer() {
      return this.currentUser.hasReadOnlyLPsAndWidgets;
    }
    async save() {
      try {
        const responses = await this.aeSaveStack.save();

        // if only the style model was saved we need to reload the blocks
        if ((responses === null || responses === void 0 ? void 0 : responses.length) === 1 && responses.find(r => {
          var _r$constructor;
          return ((_r$constructor = r.constructor) === null || _r$constructor === void 0 ? void 0 : _r$constructor.modelName) === 'style';
        })) {
          await this.store.findAll('style-block', {
            adapterOptions: {
              style: this.model.id
            }
          });
        }
        this.uiToast.showToast({
          type: 'success',
          title: this.intl.t('toast.success'),
          parent: '.aw-configurator__preview'
        });
      } catch (e) {
        this.uiToast.showToast({
          title: this.intl.t('toast.unexpectedError'),
          type: 'error',
          parent: '.aw-configurator__preview'
        });
      }
    }
    back() {
      if (this.aeSaveStack.isDirty) {
        this.showDiscardChanges = true;
        return;
      }
      this.router.transitionTo('instance.styles');
    }
    discardChanges() {
      this.aeSaveStack.discardChanges();
      this.router.transitionTo('instance.styles');
      this.showDiscardChanges = false;
    }
    setup() {
      this.aeSaveStack.batchModels = ['style-block'];
      this.aeSaveStack.requestOptions = {
        adapterOptions: {
          style: this.model.id
        }
      };
      return Promise.all([this.uiCd.fetchCorporateDesign.perform(), this.fetchVariants.perform()]);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "aeSaveStack", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "uiCd", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "showDiscardChanges", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isFullTemplate", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "blockGroups", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "styleBlocks", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "templateBlocks", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "fetchVariants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "back", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "back"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "discardChanges", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "discardChanges"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype)), _class));
});