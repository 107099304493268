define("additive-content/components/ac-landing-pages/configurator/dropzone", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/object/internals", "@ember/runloop", "prop-types", "ember-arg-types"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _internals, _runloop, _propTypes, _emberArgTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    id="{{this._elementId}}"
    class="{{this._classNames}} {{@classNames}}"
    {{did-insert this.onInsertElement}}
  >
    {{#if this.showDropzone}}
      <div class="ac-landing-pages-configurator-dropzone-container mw-100 ba br1"></div>
    {{/if}}
  </div>
  */
  {
    "id": "N9mch7Qy",
    "block": "[[[11,0],[16,1,[29,[[30,0,[\"_elementId\"]]]]],[16,0,[29,[[30,0,[\"_classNames\"]],\" \",[30,1]]]],[4,[38,0],[[30,0,[\"onInsertElement\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"showDropzone\"]],[[[1,\"    \"],[10,0],[14,0,\"ac-landing-pages-configurator-dropzone-container mw-100 ba br1\"],[12],[13],[1,\"\\n\"]],[]],null],[13]],[\"@classNames\"],false,[\"did-insert\",\"if\"]]",
    "moduleName": "additive-content/components/ac-landing-pages/configurator/dropzone.hbs",
    "isStrictMode": false
  });
  /**
   * The dropzone for the content blocks
   *
   * @module ac-landing-pages
   * @class AcLandingPagesConfiguratorDropzoneComponent
   */
  let AcLandingPagesConfiguratorDropzoneComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.boolean), (_class = class AcLandingPagesConfiguratorDropzoneComponent extends _component2.default {
    get _classNames() {
      let classNames = 'ac-landing-pages-configurator-dropzone pv3 ma-auto';
      if (this._isHovered) {
        classNames += ' ac-landing-pages-configurator-dropzone__hovered';
      }
      if (this.showDropzone) {
        classNames += ' ac-landing-pages-configurator-dropzone__visible';
      }
      return classNames;
    }

    /**
     * whether this component is hovered or not
     *
     * @computed _isHovered
     * @type {Boolean}
     * @default false
     * @private
     */
    get _isHovered() {
      var _this$_element;
      return this.hoveredDropzoneId === ((_this$_element = this._element) === null || _this$_element === void 0 ? void 0 : _this$_element.id);
    }
    constructor() {
      super(...arguments);
      /**
       * the id of the currently hovered dropzone
       *
       * @argument hoveredDropzoneId
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "hoveredDropzoneId", _descriptor, this);
      /**
       * whether the component is visible
       *
       * @argument visible
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "showDropzone", _descriptor2, this);
      /**
       * the main HTML element of this component
       *
       * @property _element
       * @type {HTMLElement}
       * @default null
       * @private
       */
      _defineProperty(this, "_element", null);
      /**
       * the element id
       *
       * @property _elementId
       * @type {String}
       * @default null
       * @private
       */
      _defineProperty(this, "_elementId", null);
      this._elementId = (0, _internals.guidFor)(this);
    }
    onInsertElement(element) {
      this._element = element;
      this._onDragOver = (0, _runloop.bind)(this, this._onDragOver);
      if (this._element) {
        this._element.addEventListener('dragover', this._onDragOver, false);
      }
    }
    willDestroyElement() {
      super.willDestroyElement(...arguments);
      if (this._element) {
        this._element.removeEventListener('dragover', this._onDragOver, false);
      }
    }
    _onDragOver(event) {
      event.preventDefault();
      event.dataTransfer.dropEffect = 'move';
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "hoveredDropzoneId", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "showDropzone", [_dec2, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onInsertElement", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onInsertElement"), _class.prototype)), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AcLandingPagesConfiguratorDropzoneComponent);
});