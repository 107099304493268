define("additive-content/components/ac-landing-pages/configurator/variation-switch", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "ember-arg-types", "prop-types", "lodash.clonedeep"], function (_exports, _component, _templateFactory, _component2, _object, _service, _emberArgTypes, _propTypes, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (gt this._variationTotalCount 1)}}
    <div
      class="ac-landing-pages-configurator-variation-switch flex items-center absolute top-0 left-0 bg-blue white font-sm"
    >
      {{#if this._isVisible}}
        <UiIconButton
          @class="color-inherit"
          @icon="small-arrow-left"
          @onClick={{fn this.changeVariation false}}
        />
        {{this._currentVariationIndex}}&nbsp;
        <span class="ac-landing-pages-configurator-variation-switch__variation-total-count">
          {{t
            "components.acLandingPages.configurator.variationSwitch.ofVariations"
            totalCount=this._variationTotalCount
          }}
        </span>
        <UiIconButton
          @class="color-inherit"
          @icon="small-arrow-right"
          @onClick={{fn this.changeVariation true}}
        />
      {{/if}}
    </div>
  {{/if}}
  */
  {
    "id": "0+g8DT9t",
    "block": "[[[41,[28,[37,1],[[30,0,[\"_variationTotalCount\"]],1],null],[[[1,\"  \"],[10,0],[14,0,\"ac-landing-pages-configurator-variation-switch flex items-center absolute top-0 left-0 bg-blue white font-sm\"],[12],[1,\"\\n\"],[41,[30,0,[\"_isVisible\"]],[[[1,\"      \"],[8,[39,2],null,[[\"@class\",\"@icon\",\"@onClick\"],[\"color-inherit\",\"small-arrow-left\",[28,[37,3],[[30,0,[\"changeVariation\"]],false],null]]],null],[1,\"\\n      \"],[1,[30,0,[\"_currentVariationIndex\"]]],[1,\" \\n      \"],[10,1],[14,0,\"ac-landing-pages-configurator-variation-switch__variation-total-count\"],[12],[1,\"\\n        \"],[1,[28,[35,4],[\"components.acLandingPages.configurator.variationSwitch.ofVariations\"],[[\"totalCount\"],[[30,0,[\"_variationTotalCount\"]]]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[8,[39,2],null,[[\"@class\",\"@icon\",\"@onClick\"],[\"color-inherit\",\"small-arrow-right\",[28,[37,3],[[30,0,[\"changeVariation\"]],true],null]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"gt\",\"ui-icon-button\",\"fn\",\"t\"]]",
    "moduleName": "additive-content/components/ac-landing-pages/configurator/variation-switch.hbs",
    "isStrictMode": false
  });
  /**
   * Handles the switch of content block variations
   *
   * @module ac-landing-pages
   * @class AcLandingPagesConfiguratorVariationSwitchComponent
   */
  let AcLandingPagesConfiguratorVariationSwitchComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec3 = (0, _emberArgTypes.arg)(_propTypes.number), _dec4 = (0, _emberArgTypes.arg)(_propTypes.func), (_class = class AcLandingPagesConfiguratorVariationSwitchComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "aeSaveStack", _descriptor, this);
      /**
       * the type of this block
       *
       * @argument type
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "contentBlock", _descriptor2, this);
      /**
       * whether the variation switch is visible
       *
       * @argument isVisible
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isVisible", _descriptor3, this);
      /**
       * the position of this block
       *
       * @argument position
       * @type {Number}
       * @default null
       */
      _initializerDefineProperty(this, "position", _descriptor4, this);
      _initializerDefineProperty(this, "onChange", _descriptor5, this);
    }
    get _isVisible() {
      return this.isVisible && !this._hideVariationSwitch;
    }
    get _hideVariationSwitch() {
      var _this$contentBlock;
      return ((_this$contentBlock = this.contentBlock) === null || _this$contentBlock === void 0 || (_this$contentBlock = _this$contentBlock.model) === null || _this$contentBlock === void 0 ? void 0 : _this$contentBlock.blockIdentifier) === 'referral';
    }
    get _currentVariationIndex() {
      var _this$contentBlock2, _this$contentBlock3;
      return Object.keys(((_this$contentBlock2 = this.contentBlock) === null || _this$contentBlock2 === void 0 ? void 0 : _this$contentBlock2.variations) || {}).indexOf((_this$contentBlock3 = this.contentBlock) === null || _this$contentBlock3 === void 0 ? void 0 : _this$contentBlock3.model.variationIdentifier) + 1;
    }
    get _variationTotalCount() {
      var _this$contentBlock4;
      return Object.keys(((_this$contentBlock4 = this.contentBlock) === null || _this$contentBlock4 === void 0 ? void 0 : _this$contentBlock4.variations) || {}).length;
    }
    _getChangedProperties(currentProperties, defaultProperties) {
      const changedPropertyGroups = {};
      currentProperties.forEach(contentGroup => {
        const variationContentGroup = defaultProperties.find(variationContentGroup => variationContentGroup.id === contentGroup.id);
        if (!variationContentGroup) {
          return;
        }
        const changedProperties = {};
        Object.keys(contentGroup.properties).forEach(propertyKey => {
          if (contentGroup.properties[propertyKey].value !== variationContentGroup.properties[propertyKey].value || contentGroup.properties[propertyKey].sync !== variationContentGroup.properties[propertyKey].sync) {
            const contentValue = contentGroup.properties[propertyKey].value;
            const variationValue = variationContentGroup.properties[propertyKey].value;
            let isEqual = false;

            // if object, compare all properties
            if (contentValue && typeof contentValue === 'object' && variationValue) {
              isEqual = true;
              Object.keys(contentValue).forEach(key => {
                if (contentValue[key] !== variationValue[key]) {
                  isEqual = false;
                  return;
                }
              });
            }
            if (!isEqual) {
              changedProperties[contentGroup.properties[propertyKey].id] = {
                value: contentGroup.properties[propertyKey].value
              };
              if (Object.keys(contentGroup.properties[propertyKey]).indexOf('sync') !== -1) {
                changedProperties[contentGroup.properties[propertyKey].id].sync = contentGroup.properties[propertyKey].sync;
              }
            }
          }
        });
        if (Object.keys(changedProperties).length > 0) {
          changedPropertyGroups[contentGroup.id] = changedProperties;
        }
      });
      return changedPropertyGroups;
    }
    _applyChangesToProperties(properties, changes) {
      let isContent = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      properties.forEach((propertiesGroup, propertiesGroupIndex) => {
        propertiesGroup.properties.forEach((property, propertyIndex) => {
          var _changes$propertiesGr;
          if (changes !== null && changes !== void 0 && (_changes$propertiesGr = changes[propertiesGroup.id]) !== null && _changes$propertiesGr !== void 0 && _changes$propertiesGr[property.id]) {
            properties[propertiesGroupIndex].properties[propertyIndex].value = changes[propertiesGroup.id][property.id].value;
            properties[propertiesGroupIndex].properties[propertyIndex].sync = changes[propertiesGroup.id][property.id].sync;
          } else if (isContent) {
            if (this.contentBlock.model.blockIdentifier === 'rooms' && property.id === 'room_categories' || this.contentBlock.model.blockIdentifier === 'offers' && property.id === 'offer_categories' || this.contentBlock.model.blockIdentifier === 'quotes' && property.id === 'quote_categories' || this.contentBlock.model.blockIdentifier === 'faqs' && property.id === 'faq_categories' || this.contentBlock.model.blockIdentifier === 'usps' && property.id === 'usp_categories') {
              return;
            }

            // clear properties if content
            if (['image', 'medium', 'offer', 'survey', 'journal', 'voucher'].includes(property.type)) {
              properties[propertiesGroupIndex].properties[propertyIndex].value = null;
              return;
            }
            if (Array.isArray(property.value)) {
              properties[propertiesGroupIndex].properties[propertyIndex].value = [];
            } else if (property.value && typeof property.value === 'object') {
              const value = property.value;
              Object.keys(value).forEach(key => {
                value[key] = null;
              });
              properties[propertiesGroupIndex].properties[propertyIndex].value = value;
            } else {
              properties[propertiesGroupIndex].properties[propertyIndex].value = null;
            }
          }
        });
      });
    }
    changeVariation() {
      var _this$contentBlock5;
      let isNextVariation = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      if (!((_this$contentBlock5 = this.contentBlock) !== null && _this$contentBlock5 !== void 0 && _this$contentBlock5.variations)) {
        return;
      }
      const currentIndex = this._currentVariationIndex - 1;
      const currentVariationKey = Object.keys(this.contentBlock.variations)[currentIndex];
      const currentVariation = this.contentBlock.variations[currentVariationKey];
      let nextIndex = isNextVariation ? currentIndex + 1 : currentIndex - 1;
      if (nextIndex < 0) {
        nextIndex = this._variationTotalCount - 1;
      } else if (nextIndex >= this._variationTotalCount) {
        nextIndex = 0;
      }
      const nextVariationKey = Object.keys(this.contentBlock.variations)[nextIndex];
      const nextVariation = this.contentBlock.variations[nextVariationKey];
      const contentBlockModel = this.contentBlock.model;

      // check for changed content
      const changedContent = this._getChangedProperties(contentBlockModel.content, currentVariation.content);
      const newContent = (0, _lodash.default)(nextVariation.content);
      this._applyChangesToProperties(newContent, changedContent, true);

      // check for changed style
      const changedStyle = this._getChangedProperties(contentBlockModel.style, currentVariation.style);
      const newStyle = (0, _lodash.default)(nextVariation.style);
      this._applyChangesToProperties(newStyle, changedStyle);
      contentBlockModel.set('content', newContent);
      contentBlockModel.set('style', newStyle);
      contentBlockModel.set('styleBlockId', nextVariation);
      contentBlockModel.set('variationIdentifier', nextVariationKey);
      contentBlockModel.set('name', nextVariation.name);
      this.aeSaveStack.pushChanges(contentBlockModel);
      this.onChange(contentBlockModel, this.position);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "aeSaveStack", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "contentBlock", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isVisible", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "position", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "onChange", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "changeVariation", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeVariation"), _class.prototype)), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AcLandingPagesConfiguratorVariationSwitchComponent);
});