define("additive-content/routes/instance/contents/pages/index/page/edit", ["exports", "@ember/routing/route", "@ember/service", "@additive-apps/ui/mixins/ui-full-width-view", "additive-content/validations/page"], function (_exports, _route, _service, _uiFullWidthView, _page) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let InstanceContentsPagesIndexPageEditRoute = _exports.default = (_class = class InstanceContentsPagesIndexPageEditRoute extends _route.default.extend(_uiFullWidthView.default) {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor2, this);
    }
    renderTemplate() {
      this.render('instance/contents/pages/index/page/edit', {
        into: 'application'
      });
    }
    model() {
      return super.model(...arguments);
    }
    setupController(controller, model) {
      super.setupController(...arguments);
      if (controller.language) {
        this.uiAppSettings.setLocale(controller.language);
      }
      if (model.get('language') !== this.uiAppSettings.currentLocale) {
        // reset multilingual properties
        model.setProperties({
          name: null,
          description: null,
          language: this.uiAppSettings.currentLocale
        });
      }
      controller.validation = (0, _page.pageValidation)(this.intl);
    }
    resetController(controller, isExiting) {
      if (isExiting) {
        var _this$uiAppSettings$l;
        controller.language = null;
        this.uiAppSettings.setLocale((_this$uiAppSettings$l = this.uiAppSettings.languages) === null || _this$uiAppSettings$l === void 0 ? void 0 : _this$uiAppSettings$l.defaultLanguage);
        controller.model.rollbackAttributes();
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});