define("additive-content/components/ac-styles/duplicate-dialog", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "@glimmer/tracking", "ember-arg-types", "prop-types", "ember-changeset", "ember-changeset-validations", "additive-content/validations/style", "additive-content/utils/constants"], function (_exports, _component, _templateFactory, _component2, _object, _service, _tracking, _emberArgTypes, _propTypes, _emberChangeset, _emberChangesetValidations, _style, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <UiModal
    @title={{t "components.acStyles.duplicateDialog.title"}}
    @size="sm"
    @onClose={{this.close}} as |modal|
  >
    <modal.header />
    <modal.content as |content|>
      <content.body {{did-insert (this.setup)}}>
        <UiInput
          @label={{t "components.acStyles.duplicateDialog.name"}}
          @value={{this.changeset.name}}
          @isTouched={{this.isTouched}}
          @errorMessage={{this.changeset.error.name.validation.firstObject}}
        />
      </content.body>
    </modal.content>
  
    <modal.footer>
      <UiButton
        @label={{t "global.actions.save"}}
        @onClick={{this.save}}
        @isPromise={{true}}
        @isSecondary={{true}}
      />
    </modal.footer>
  </UiModal>
  */
  {
    "id": "i/5da5/t",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@size\",\"@onClose\"],[[28,[37,1],[\"components.acStyles.duplicateDialog.title\"],null],\"sm\",[30,0,[\"close\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"header\"]],null,null,null],[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"body\"]],[[4,[38,2],[[28,[30,0,[\"setup\"]],null,null]],null]],null,[[\"default\"],[[[[1,\"\\n      \"],[8,[39,3],null,[[\"@label\",\"@value\",\"@isTouched\",\"@errorMessage\"],[[28,[37,1],[\"components.acStyles.duplicateDialog.name\"],null],[30,0,[\"changeset\",\"name\"]],[30,0,[\"isTouched\"]],[30,0,[\"changeset\",\"error\",\"name\",\"validation\",\"firstObject\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\\n  \"],[8,[30,1,[\"footer\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,4],null,[[\"@label\",\"@onClick\",\"@isPromise\",\"@isSecondary\"],[[28,[37,1],[\"global.actions.save\"],null],[30,0,[\"save\"]],true,true]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"modal\",\"content\"],false,[\"ui-modal\",\"t\",\"did-insert\",\"ui-input\",\"ui-button\"]]",
    "moduleName": "additive-content/components/ac-styles/duplicate-dialog.hbs",
    "isStrictMode": false
  });
  /**
   * Dialog that is shown when a user wants to duplicate a style
   *
   * @module ac-styles
   * @class AcStylesDuplicateDialog
   */
  let AcStylesDuplicateDialog = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.func), (_class = class AcStylesDuplicateDialog extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor4, this);
      _initializerDefineProperty(this, "uiToast", _descriptor5, this);
      /**
       * the changeset of the style
       *
       * @argument changeset
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "changeset", _descriptor6, this);
      /**
       * whether the form is touched
       *
       * @argument isTouched
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isTouched", _descriptor7, this);
      /**
       * the style resource
       *
       * @argument style
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "style", _descriptor8, this);
      /**
       * on close callback
       *
       * @function onClose
       */
      _initializerDefineProperty(this, "onClose", _descriptor9, this);
    }
    async save() {
      try {
        this.isTouched = true;
        const adapter = this.store.adapterFor('style');
        const url = adapter.urlForFindRecord(this.style.id, 'style');
        const response = await this.authenticatedFetch.fetch(`${url}/duplicate`, {
          method: 'POST',
          body: JSON.stringify({
            name: this.changeset.get('name')
          }),
          headers: _constants.ACCEPT_HEADER
        }, null, [422, 403]);
        if (!response.ok) {
          throw response;
        }
        const body = await response.json();
        this.store.pushPayload('style', body);
        this.onClose();
        this.uiToast.showToast({
          type: 'success',
          title: this.intl.t('toast.success')
        });
      } catch (e) {
        if ((e === null || e === void 0 ? void 0 : e.status) === 403) {
          this.uiDialog.showError(this.intl.t('routes.styles.exceededError.title'), this.intl.t('routes.styles.exceededError.description'));
        } else if ((e === null || e === void 0 ? void 0 : e.status) === 422) {
          this.changeset.pushErrors('name', this.intl.t('errors.nameExists'));
        } else {
          this.uiToast.showToast({
            type: 'error',
            title: this.intl.t('toast.unexpectedError'),
            duration: 2000
          });
        }
      }
    }
    setup() {
      const validation = (0, _style.styleValidation)(this.intl);
      this.changeset = new _emberChangeset.default(this.style, (0, _emberChangesetValidations.default)(validation), validation);
    }
    close() {
      this.uiDialog.showDiscardChangesConfirm(() => {
        this.changeset.destroy();
        this.onClose();
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "changeset", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isTouched", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "style", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype)), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AcStylesDuplicateDialog);
});