define("additive-content/components/ac-contents/tours/recommended-months-select", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "@ember/object", "ember-arg-types", "prop-types", "date-fns/format"], function (_exports, _component, _templateFactory, _component2, _service, _object, _emberArgTypes, _propTypes, _format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isReadOnly}}
    <div class="ac-recommended-months-select__container flex flex-row font-xs pv05">
      {{#each this.months as |month index|}}
        <div
          class="ph05 mr05 br1 readonly-month
  
          {{if (get this.isMonthSelected index) "bg-blue--10 blue" "readonly-month--inactive"}}"
        >
          {{month}}
        </div>
      {{/each}}
    </div>
  {{else}}
    <div class="ac-recommended-months-select flex flex-column mv1">
      <span class="font-sm secondary pb1">
        {{t "contents.tours.tour.properties.recommendedMonths"}}
      </span>
      <div class="ac-recommended-months-select__container flex flex-row flex-wrap">
        {{#each this.months as |month index|}}
          <UiChip
            @text={{month}}
            @isSelected={{get this.isMonthSelected index}}
            @onChange={{fn this.onMonthSelect index}}
            @theme="transparent"
            @classNames="mr1 mb1"
          />
        {{/each}}
      </div>
    </div>
  {{/if}}
  */
  {
    "id": "b6D7voG1",
    "block": "[[[41,[30,0,[\"isReadOnly\"]],[[[1,\"  \"],[10,0],[14,0,\"ac-recommended-months-select__container flex flex-row font-xs pv05\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"months\"]]],null]],null],null,[[[1,\"      \"],[10,0],[15,0,[29,[\"ph05 mr05 br1 readonly-month\\n\\n        \",[52,[28,[37,3],[[30,0,[\"isMonthSelected\"]],[30,2]],null],\"bg-blue--10 blue\",\"readonly-month--inactive\"]]]],[12],[1,\"\\n        \"],[1,[30,1]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1,2]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"ac-recommended-months-select flex flex-column mv1\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"font-sm secondary pb1\"],[12],[1,\"\\n      \"],[1,[28,[35,4],[\"contents.tours.tour.properties.recommendedMonths\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"ac-recommended-months-select__container flex flex-row flex-wrap\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"months\"]]],null]],null],null,[[[1,\"        \"],[8,[39,5],null,[[\"@text\",\"@isSelected\",\"@onChange\",\"@theme\",\"@classNames\"],[[30,3],[28,[37,3],[[30,0,[\"isMonthSelected\"]],[30,4]],null],[28,[37,6],[[30,0,[\"onMonthSelect\"]],[30,4]],null],\"transparent\",\"mr1 mb1\"]],null],[1,\"\\n\"]],[3,4]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"month\",\"index\",\"month\",\"index\"],false,[\"if\",\"each\",\"-track-array\",\"get\",\"t\",\"ui-chip\",\"fn\"]]",
    "moduleName": "additive-content/components/ac-contents/tours/recommended-months-select.hbs",
    "isStrictMode": false
  });
  /**
   * Enables recommended month selection in tour edit and create
   *
   * @class AcContentsToursRecommendendedMonthsSelectComponent
   */
  let AcContentsToursRecommendendedMonthsSelectComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.array), _dec2 = (0, _emberArgTypes.arg)(_propTypes.func), _dec3 = (0, _emberArgTypes.arg)(_propTypes.bool), (_class = class AcContentsToursRecommendendedMonthsSelectComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "uiLocale", _descriptor, this);
      /**
       * the currently selected months
       *
       * @argument selectedMonths
       * @type {Array}
       */
      _initializerDefineProperty(this, "selectedMonths", _descriptor2, this);
      /**
       * the callback function that changes the selected months
       *
       * @argument onSelect
       * @type {Function}
       */
      _initializerDefineProperty(this, "onSelect", _descriptor3, this);
      /**
       * whether it is read only
       *
       * @argument isReadOnly
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "isReadOnly", _descriptor4, this);
    }
    get months() {
      return Array.from(Array(12).keys()).map(i => (0, _format.default)(new Date(0, i + 1, 0), 'MMM', {
        locale: this.uiLocale.dateFnsLocale
      }));
    }
    get isMonthSelected() {
      return this.months.map((_, i) => {
        var _this$selectedMonths;
        return ((_this$selectedMonths = this.selectedMonths) === null || _this$selectedMonths === void 0 ? void 0 : _this$selectedMonths.indexOf(i + 1)) >= 0;
      });
    }
    onMonthSelect(index) {
      const selectedMonths = [...(this.selectedMonths || [])];
      const indexOfMonth = selectedMonths.indexOf(index + 1);
      if (indexOfMonth >= 0) {
        selectedMonths.splice(indexOfMonth, 1);
      } else {
        selectedMonths.push(index + 1);
      }
      this.onSelect(selectedMonths);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "uiLocale", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "selectedMonths", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "onSelect", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isReadOnly", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onMonthSelect", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onMonthSelect"), _class.prototype)), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AcContentsToursRecommendendedMonthsSelectComponent);
});