define("additive-content/components/ac-widgets/delete-dialog", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "ember-concurrency", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _service, _emberConcurrency, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <UiModal
    @title={{t "components.acWidgets.deleteDialog.title"}}
    @theme="error"
    @onClose={{this.onClose}} as |modal|
  >
    <modal.header />
    <modal.content as |content|>
      <content.body>
        <div class="font-md">
          {{t "components.acWidgets.deleteDialog.description"}}
        </div>
      </content.body>
    </modal.content>
    <modal.footer>
      <UiButton
        @label={{t "global.actions.delete"}}
        @onClick={{perform this.deleteWidget}}
        @isPromise={{true}}
        @isDanger={{true}}
        @isSecondary={{true}}
      />
    </modal.footer>
  </UiModal>
  */
  {
    "id": "TioeX2ek",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@theme\",\"@onClose\"],[[28,[37,1],[\"components.acWidgets.deleteDialog.title\"],null],\"error\",[30,0,[\"onClose\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"header\"]],null,null,null],[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"body\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"font-md\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[\"components.acWidgets.deleteDialog.description\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n  \"],[8,[30,1,[\"footer\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@label\",\"@onClick\",\"@isPromise\",\"@isDanger\",\"@isSecondary\"],[[28,[37,1],[\"global.actions.delete\"],null],[28,[37,3],[[30,0,[\"deleteWidget\"]]],null],true,true,true]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"modal\",\"content\"],false,[\"ui-modal\",\"t\",\"ui-button\",\"perform\"]]",
    "moduleName": "additive-content/components/ac-widgets/delete-dialog.hbs",
    "isStrictMode": false
  });
  let AcWidgetsDeleteDialog = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.func), _dec3 = (0, _emberConcurrency.task)(function* () {
    try {
      yield this.widget.destroyRecord();
      yield this.uiFilter.reset('widgets');
      this.uiToast.showToast({
        type: 'success',
        title: this.intl.t('global.toast.success.savedChanges')
      });
    } catch (error) {
      var _error$errors;
      if ((error === null || error === void 0 || (_error$errors = error.errors) === null || _error$errors === void 0 ? void 0 : _error$errors.length) > 0) {
        const errorData = error.errors[0];

        // handle conflict error
        if (errorData.status === 409) {
          const {
            intl
          } = this;
          this.uiDialog.showConflictError(intl.t('components.acWidgets.deleteDialog.conflictError.title'), intl.t('components.acWidgets.deleteDialog.conflictError.description'), errorData.dependencies);
          return;
        }
      }
      throw new Error('[WIDGET] delete widget', error);
    } finally {
      this.onClose();
    }
  }), (_class = class AcWidgetsDeleteDialog extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor2, this);
      _initializerDefineProperty(this, "uiFilter", _descriptor3, this);
      _initializerDefineProperty(this, "uiToast", _descriptor4, this);
      /**
       * The widget to be deleted
       * @argument widget
       * @type {Model}
       * @default null
       *
       */
      _initializerDefineProperty(this, "widget", _descriptor5, this);
      _initializerDefineProperty(this, "onClose", _descriptor6, this);
      _initializerDefineProperty(this, "deleteWidget", _descriptor7, this);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "uiFilter", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "widget", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "deleteWidget", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AcWidgetsDeleteDialog);
});