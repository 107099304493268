define("additive-content/models/event", ["exports", "@ember-data/model", "@ember/object/computed", "@ember/service", "date-fns/format", "@ember/object"], function (_exports, _model, _computed, _service, _format, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let EventModel = _exports.default = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('date'), _dec4 = (0, _model.attr)('date'), _dec5 = (0, _model.attr)('date'), _dec6 = (0, _model.attr)('boolean'), _dec7 = (0, _model.attr)('boolean'), _dec8 = (0, _model.attr)('number'), _dec9 = (0, _model.hasMany)('category', {
    async: true
  }), _dec10 = (0, _computed.gt)('images.length', 0), _dec11 = (0, _computed.alias)('images.firstObject'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('date'), _dec14 = (0, _model.attr)('date'), _dec15 = (0, _model.attr)('boolean'), _dec16 = (0, _model.attr)('string'), (_class = class EventModel extends _model.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "uiLocale", _descriptor2, this);
      _initializerDefineProperty(this, "name", _descriptor3, this);
      _initializerDefineProperty(this, "description", _descriptor4, this);
      _initializerDefineProperty(this, "date", _descriptor5, this);
      _initializerDefineProperty(this, "startsAt", _descriptor6, this);
      _initializerDefineProperty(this, "endsAt", _descriptor7, this);
      _initializerDefineProperty(this, "timeStart", _descriptor8, this);
      _initializerDefineProperty(this, "timeEnd", _descriptor9, this);
      _initializerDefineProperty(this, "soldOut", _descriptor10, this);
      _initializerDefineProperty(this, "registrationRequired", _descriptor11, this);
      _initializerDefineProperty(this, "rating", _descriptor12, this);
      _initializerDefineProperty(this, "categories", _descriptor13, this);
      _initializerDefineProperty(this, "tags", _descriptor14, this);
      _initializerDefineProperty(this, "interests", _descriptor15, this);
      _initializerDefineProperty(this, "travelMotivations", _descriptor16, this);
      _initializerDefineProperty(this, "travelTimes", _descriptor17, this);
      _initializerDefineProperty(this, "rrule", _descriptor18, this);
      _initializerDefineProperty(this, "images", _descriptor19, this);
      _initializerDefineProperty(this, "hasImage", _descriptor20, this);
      _initializerDefineProperty(this, "primaryImage", _descriptor21, this);
      _initializerDefineProperty(this, "availableLanguages", _descriptor22, this);
      _initializerDefineProperty(this, "publishedLanguages", _descriptor23, this);
      _initializerDefineProperty(this, "language", _descriptor24, this);
      _initializerDefineProperty(this, "publicFrom", _descriptor25, this);
      _initializerDefineProperty(this, "publicUntil", _descriptor26, this);
      _initializerDefineProperty(this, "timeLimited", _descriptor27, this);
      _initializerDefineProperty(this, "publishedState", _descriptor28, this);
      _defineProperty(this, "_allInterestTags", []);
    }
    get categoryNames() {
      return this.categories.map(category => category.name).join(', ');
    }
    get listDescription() {
      const timeFormat = this.uiLocale.locale === 'de' ? 'HH:mm' : 'hh:mm aaaa';
      const formattedStart = this.timeStart && (0, _format.default)(new Date(this.timeStart), timeFormat);
      const formattedEnd = this.timeEnd && (0, _format.default)(new Date(this.timeEnd), timeFormat);
      let timeSpan = [formattedStart, formattedEnd].filter(time => !!time).join(' - ');
      if (timeSpan) {
        timeSpan += ` ${this.intl.t('contents.events.event.clock')}`;
      }
      return [this.categoryNames, timeSpan].filter(descriptionPart => !!descriptionPart).join('&nbsp;&nbsp;—&nbsp;&nbsp;');
    }
    setAllInterestTags() {
      var _this$interests;
      this._allInterestTags = (_this$interests = this.interests) === null || _this$interests === void 0 ? void 0 : _this$interests.concat(this.travelMotivations, this.travelTimes);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "uiLocale", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "date", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "startsAt", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "endsAt", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "timeStart", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "timeEnd", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "soldOut", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "registrationRequired", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "rating", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "categories", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "tags", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "interests", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "travelMotivations", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "travelTimes", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "rrule", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "images", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "hasImage", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "primaryImage", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "availableLanguages", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "publishedLanguages", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "language", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "publicFrom", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "publicUntil", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "timeLimited", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "publishedState", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setAllInterestTags", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setAllInterestTags"), _class.prototype)), _class));
});