define("additive-content/controllers/instance/styles/style/index", ["exports", "@ember/controller", "@glimmer/tracking", "@ember/service", "@ember/object/computed", "@ember/object", "lodash.merge"], function (_exports, _controller, _tracking, _service, _computed, _object, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let InstanceStylesStyleIndexController = _exports.default = (_dec = (0, _computed.alias)('currentUser.currentOrganization.id'), (_class = class InstanceStylesStyleIndexController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "aeSaveStack", _descriptor, this);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor2, this);
      _initializerDefineProperty(this, "currentUser", _descriptor3, this);
      _initializerDefineProperty(this, "intl", _descriptor4, this);
      _initializerDefineProperty(this, "uiCd", _descriptor5, this);
      _initializerDefineProperty(this, "activeTab", _descriptor6, this);
      _initializerDefineProperty(this, "tabs", _descriptor7, this);
      _initializerDefineProperty(this, "organizationSlug", _descriptor8, this);
    }
    get _isViewer() {
      return this.currentUser.hasReadOnlyLPsAndWidgets;
    }
    get _model() {
      if (!this.model || !this.uiCd.corporateDesignColors) {
        return null;
      }
      Object.keys(this.model.styles.colors).forEach(key => {
        const syncedColor = this.model.styles.colors[key].syncedValue;

        //  eslint-disable-next-line ember/no-side-effects
        this.model.styles.colors[key].syncedColor = this.uiCd.corporateDesignColors[syncedColor].color;

        //  eslint-disable-next-line ember/no-side-effects
        this.model.styles.colors[key].syncedContrastColor = this.uiCd.corporateDesignColors[syncedColor].contrastColor;
      });
      return this.model;
    }
    async onUpdate(key, value) {
      if (typeof value === 'object') {
        const oldValue = this.model.get(`styles.${key}`);
        value = (0, _lodash.default)({}, oldValue, value);
      }

      // create nested object from key with dot notation
      const nestedObject = {};
      key.split('.').reduce((aggregator, part, index, parts) => {
        aggregator[part] = index === parts.length - 1 ? value : {};
        return aggregator[part];
      }, nestedObject);
      this.model.set(`styles`, (0, _lodash.default)({}, this.model.styles, nestedObject));
      const currentStyleBlockChanges = [];
      this.aeSaveStack._stack.forEach(changedModel => {
        var _changedModel$constru;
        if (((_changedModel$constru = changedModel.constructor) === null || _changedModel$constru === void 0 ? void 0 : _changedModel$constru.modelName) !== 'style-block') {
          return;
        }
        currentStyleBlockChanges.push(changedModel.serialize({
          includeId: true
        }));
      });
      currentStyleBlockChanges.forEach(currentStyleBlockChange => {
        const storeModel = this.store.peekRecord('style-block', currentStyleBlockChange.id);
        const newStyle = [];
        currentStyleBlockChange.style.forEach(styleGroup => {
          const style = {
            id: styleGroup.id,
            name: styleGroup.name,
            properties: []
          };
          styleGroup.properties.forEach(styleProperty => {
            const changes = {
              value: styleProperty.value
            };
            if (Object.keys(styleProperty).indexOf('sync') > -1) {
              changes.sync = styleProperty.sync;
            }
            style.properties.push(Object.assign({}, styleProperty, changes));
          });
          newStyle.push(style);
        });
        storeModel.set('style', newStyle);
      });
      await this.model.save({
        adapterOptions: {
          isPreview: true
        }
      });
      this.aeSaveStack.pushChanges(this.model);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "aeSaveStack", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "uiCd", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "activeTab", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return this.tabs[0];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "tabs", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [{
        title: this.intl.t('styles.configurator.general.title'),
        name: 'general'
      }, {
        title: this.intl.t('styles.configurator.colors.title'),
        name: 'colors'
      }, {
        title: this.intl.t('styles.configurator.typography.title'),
        name: 'typography'
      }];
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "organizationSlug", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onUpdate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onUpdate"), _class.prototype)), _class));
});