define("additive-content/components/ac-landing-pages/duplicate-dialog", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "ember-arg-types", "ember-changeset", "ember-changeset-validations", "ember-concurrency", "prop-types", "additive-content/validations/landing-page"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _service, _emberArgTypes, _emberChangeset, _emberChangesetValidations, _emberConcurrency, _propTypes, _landingPage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <UiModal
    @title={{t "components.acLandingPages.duplicateDialog.title"}}
    @size="sm"
    @onClose={{this.onDialogClose}} as |dialog|
  >
    <dialog.header />
    <dialog.content as |content|>
      <content.body>
        <UiInput
          @label={{t "components.acLandingPages.duplicateDialog.properties.name"}}
          @value={{this._changeset.name}}
          @isTouched={{this._isTouched}}
          @errorMessage={{this._changeset.error.name.validation.firstObject}}
        />
      </content.body>
    </dialog.content>
  
    <dialog.footer>
      <UiButton
        @label={{t "global.actions.save"}}
        @onClick={{perform this.save}}
        @isSecondary={{true}}
        @isPromise={{true}}
      />
    </dialog.footer>
  </UiModal>
  */
  {
    "id": "kfJgWmNh",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@size\",\"@onClose\"],[[28,[37,1],[\"components.acLandingPages.duplicateDialog.title\"],null],\"sm\",[30,0,[\"onDialogClose\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"header\"]],null,null,null],[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"body\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[8,[39,2],null,[[\"@label\",\"@value\",\"@isTouched\",\"@errorMessage\"],[[28,[37,1],[\"components.acLandingPages.duplicateDialog.properties.name\"],null],[30,0,[\"_changeset\",\"name\"]],[30,0,[\"_isTouched\"]],[30,0,[\"_changeset\",\"error\",\"name\",\"validation\",\"firstObject\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\\n  \"],[8,[30,1,[\"footer\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,3],null,[[\"@label\",\"@onClick\",\"@isSecondary\",\"@isPromise\"],[[28,[37,1],[\"global.actions.save\"],null],[28,[37,4],[[30,0,[\"save\"]]],null],true,true]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"dialog\",\"content\"],false,[\"ui-modal\",\"t\",\"ui-input\",\"ui-button\",\"perform\"]]",
    "moduleName": "additive-content/components/ac-landing-pages/duplicate-dialog.hbs",
    "isStrictMode": false
  });
  /**
   * Dialog that is shown when a user wants duplicate a landing page
   *
   * @module ac-landing-pages
   * @class AcLandingPagesDuplicateDialog
   */
  let AcLandingPagesDuplicateDialog = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberConcurrency.task)(function* () {
    const adapter = this.store.adapterFor('landing-page');
    const apiModelUrl = adapter.buildURL('landing-page', this.landingPage.id);
    const changeset = this._changeset;
    this._isTouched = true;
    try {
      yield changeset.validate();
      if (!changeset.get('isValid')) {
        return;
      }
      const body = {
        name: changeset.get('name')
      };
      const response = yield this.authenticatedFetch.fetch(`${apiModelUrl}/duplicate`, {
        method: 'POST',
        body: JSON.stringify(body)
      }, null, [422, 403]);
      if (!(response !== null && response !== void 0 && response.ok)) throw response;
      const json = yield response.json();
      const {
        landingPage
      } = json;
      this.store.pushPayload('landing-page', landingPage);
      this.router.transitionTo(`instance.landing-pages.landing-page`, landingPage.id);
      this.uiToast.showToast({
        type: 'success',
        title: this.intl.t('toast.success')
      });
      this._onClose();
    } catch (e) {
      const error = yield e === null || e === void 0 ? void 0 : e.json();
      if ((error === null || error === void 0 ? void 0 : error.message) === 'name_not_unique') {
        changeset.pushErrors('name', this.intl.t('errors.nameExists'));
        return;
      }
      if (error !== null && error !== void 0 && error.message) {
        this.uiDialog.showError(this.intl.t('global.errors.title'), error === null || error === void 0 ? void 0 : error.message);
        return;
      }
      this.uiToast.showToast({
        type: 'error',
        title: this.intl.t('toast.unexpectedError'),
        duration: 2000
      });
    }
  }), _dec3 = (0, _emberArgTypes.arg)(_propTypes.func), (_class = class AcLandingPagesDuplicateDialog extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _initializerDefineProperty(this, "store", _descriptor4, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor5, this);
      _initializerDefineProperty(this, "uiToast", _descriptor6, this);
      /**
       * the landing page
       *
       * @argument landingPage
       * @type {object}
       */
      _initializerDefineProperty(this, "landingPage", _descriptor7, this);
      /**
       * the changeset
       *
       * @property _changeset
       * @type {object}
       * @default null
       */
      _initializerDefineProperty(this, "_changeset", _descriptor8, this);
      /**
       * whether the input was changed
       *
       * @property _isTouched
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "_isTouched", _descriptor9, this);
      /**
       * saves the current model instance if the changeset isValid
       *
       * @function save
       * @type {Task}
       */
      _initializerDefineProperty(this, "save", _descriptor10, this);
      /**
       * on close callback
       *
       * @function onClose
       */
      _initializerDefineProperty(this, "onClose", _descriptor11, this);
      const validation = (0, _landingPage.duplicateLandingPageValidation)(this.intl);
      const changeset = new _emberChangeset.default({
        name: ''
      }, (0, _emberChangesetValidations.default)(validation), validation);
      this._changeset = changeset;
    }
    /**
     * destroys the changeset and closes the dialog
     *
     * @function onClose
     */
    _onClose(didCreate) {
      const changeset = this._changeset;
      changeset === null || changeset === void 0 || changeset.destroy();
      this.onClose(didCreate);
    }
    /**
     * destroys the changeset and deletes the record in case it is new
     *
     * @function onDialogClose
     */
    onDialogClose() {
      if (this._changeset.get('isDirty')) {
        this.uiDialog.showDiscardChangesConfirm(() => {
          this._onClose();
        });
        return;
      }
      this._onClose();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "landingPage", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "_changeset", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "_isTouched", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "save", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onDialogClose", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onDialogClose"), _class.prototype)), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AcLandingPagesDuplicateDialog);
});