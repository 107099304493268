define("additive-content/controllers/instance/styles/style/block/variation", ["exports", "@ember/controller", "@ember/service", "@ember/object", "@glimmer/tracking"], function (_exports, _controller, _service, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let InstanceStylesIndexStyleController = _exports.default = (_class = class InstanceStylesIndexStyleController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "uiCd", _descriptor, this);
      _initializerDefineProperty(this, "aeSaveStack", _descriptor2, this);
      _initializerDefineProperty(this, "currentUser", _descriptor3, this);
      _initializerDefineProperty(this, "intl", _descriptor4, this);
      _initializerDefineProperty(this, "router", _descriptor5, this);
      _initializerDefineProperty(this, "siblingVariations", _descriptor6, this);
    }
    get buttonOptions() {
      const blocks = this.store.peekAll('style-block');
      const buttons = blocks.filterBy('blockIdentifier', 'button');
      return buttons.map(button => ({
        value: button.variationIdentifier,
        label: button.name
      }));
    }
    get isReadOnly() {
      var _this$currentUser;
      return (_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.isViewer;
    }
    get colorOptions() {
      const {
        colors
      } = this.model.style.styles;
      return Object.keys(colors).map(key => {
        const c = colors[key];
        const color = c.sync ? c.syncedColor : c.color;
        const contrastColor = c.sync ? c.syncedContrastColor : c.contrastColor;
        return {
          key,
          value: {
            color,
            contrastColor
          }
        };
      });
    }

    /**
     * format errors in the style object
     *
     * @computed styleErrors
     * @type {Object}
     */
    get styleErrors() {
      const errors = {};
      const errArray = this.aeSaveStack.errors.filter(err => {
        return err.attribute === 'style' && err.variation_identifier === this.model.variation.variationIdentifier && err.block_identifier === this.model.variation.blockIdentifier;
      });
      errArray.forEach(obj => {
        let error;
        if (this.intl.exists(`routes.styles.style.errors.${obj.error}`)) {
          error = this.intl.t(`routes.styles.style.errors.${obj.error}`);
        } else {
          error = this.intl.t(`routes.styles.style.errors.error`);
        }
        errors[obj.property_id] = {
          error
        };
      });
      return errors;
    }

    /**
     * error for this variation
     *
     * @computed variationError
     * @type {String}
     */
    get variationError() {
      const errArray = this.aeSaveStack.errors.filter(err => {
        return err.variation_identifier === this.model.variation.variationIdentifier && err.block_identifier === this.model.variation.blockIdentifier && err.error;
      });
      if (errArray.length) {
        if (this.intl.exists(`routes.styles.style.errors.${errArray[0].error}`)) {
          return this.intl.t(`routes.styles.style.errors.${errArray[0].error}`);
        } else {
          return this.intl.t(`routes.styles.style.errors.error`);
        }
      }
      return null;
    }
    onUpdate() {
      this.aeSaveStack.pushChanges(this.model.variation);
    }
    transitionToSibling(variation) {
      this.router.transitionTo(`instance.styles.style.block.variation`, variation.variationIdentifier);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "uiCd", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "aeSaveStack", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "siblingVariations", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onUpdate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onUpdate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "transitionToSibling", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToSibling"), _class.prototype)), _class);
});