define("additive-content/components/ac-contents/events/rrule-delete", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "ember-arg-types", "prop-types", "@glimmer/tracking", "ember-concurrency", "date-fns/format"], function (_exports, _component, _templateFactory, _component2, _object, _service, _emberArgTypes, _propTypes, _tracking, _emberConcurrency, _format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <UiModal
    @title={{t "contents.events.deleteDialog.title"}}
    @size="sm"
    @onClose={{this.onClose}} as |m|
  >
    <m.header />
    <m.content as |c|>
      <c.body @classNames="pt2">
        <UiRadioButtonGroup
          @options={{this.options}}
          @selected={{this.selected}}
          @class="flex-column" as |group option|
        >
          <group.option @label={{option.name}} @value={{option.value}} @class="mv1" />
        </UiRadioButtonGroup>
      </c.body>
    </m.content>
  
    <m.footer>
      <UiButton
        @label={{t "global.actions.delete"}}
        @isSecondary={{true}}
        @onClick={{this.deleteContent}}
      />
    </m.footer>
  </UiModal>
  */
  {
    "id": "27hXjkk1",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@size\",\"@onClose\"],[[28,[37,1],[\"contents.events.deleteDialog.title\"],null],\"sm\",[30,0,[\"onClose\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"header\"]],null,null,null],[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"body\"]],null,[[\"@classNames\"],[\"pt2\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,2],null,[[\"@options\",\"@selected\",\"@class\"],[[30,0,[\"options\"]],[30,0,[\"selected\"]],\"flex-column\"]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,3,[\"option\"]],null,[[\"@label\",\"@value\",\"@class\"],[[30,4,[\"name\"]],[30,4,[\"value\"]],\"mv1\"]],null],[1,\"\\n      \"]],[3,4]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\\n  \"],[8,[30,1,[\"footer\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,3],null,[[\"@label\",\"@isSecondary\",\"@onClick\"],[[28,[37,1],[\"global.actions.delete\"],null],true,[30,0,[\"deleteContent\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"m\",\"c\",\"group\",\"option\"],false,[\"ui-modal\",\"t\",\"ui-radio-button-group\",\"ui-button\"]]",
    "moduleName": "additive-content/components/ac-contents/events/rrule-delete.hbs",
    "isStrictMode": false
  });
  let AcContentsEventsRruleDeleteComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.func), _dec3 = (0, _emberArgTypes.arg)(_propTypes.func), (_class = class AcContentsEventsRruleDeleteComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "uiToast", _descriptor4, this);
      _initializerDefineProperty(this, "content", _descriptor5, this);
      _initializerDefineProperty(this, "onDelete", _descriptor6, this);
      _initializerDefineProperty(this, "onClose", _descriptor7, this);
      _initializerDefineProperty(this, "selected", _descriptor8, this);
    }
    get options() {
      return ['all', 'this'].map(opt => ({
        name: this.intl.t(`contents.events.deleteDialog.${opt}`),
        value: opt
      }));
    }
    async deleteContent() {
      const {
        content
      } = this;
      try {
        let tasks = [];
        if (this.selected == 'all') {
          tasks.push(content.destroyRecord());
        } else {
          const adapter = this.store.adapterFor('event');
          const baseUrl = adapter.buildURL('event', content.id);
          const url = `${baseUrl}/date/${(0, _format.default)(content.date, 'yyyy-MM-dd')}`;
          tasks.push(this.authenticatedFetch.fetch(url, {
            method: 'DELETE'
          }));
        }
        tasks.push((0, _emberConcurrency.timeout)(250));
        await Promise.all(tasks);
        this.onDelete();
        this.onClose();
        this.uiToast.showToast({
          type: 'success',
          title: this.intl.t('uiToast.success'),
          duration: 2000
        });
      } catch (e) {
        this.uiToast.showToast({
          title: this.intl.t('uiToast.unexpectedError'),
          type: 'error'
        });
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "content", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "onDelete", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "selected", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'all';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "deleteContent", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "deleteContent"), _class.prototype)), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AcContentsEventsRruleDeleteComponent);
});