define("additive-content/components/ac-contents/rates/prices-table", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "rsvp", "@ember/service", "@ember/object", "@ember/string", "ember-arg-types", "prop-types", "ember-concurrency"], function (_exports, _component, _templateFactory, _component2, _tracking, _rsvp, _service, _object, _string, _emberArgTypes, _propTypes, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <UiTable
    @columns= {{this.columns}}
    @rows= {{this.tableRows}}
    @isLoading={{this.fetchRooms.isRunning}}
    @formats={{this.formats}}
    @isError={{this.isError}}
    @onReload={{perform this.fetchRooms}}
    @classNames="ma-auto"
    {{did-insert (perform this.fetchRooms)}}
    {{did-update (perform this.fetchRooms) this.season.id}}
    as |ta|
  >
    <ta.head/>
    <ta.body as |body|>
      <body.row
        @hasActions={{not this._isViewer}}
        @buttonAction={{hash icon="edit" action=this.onRowClick}}
      />
    </ta.body>
  
    <ta.empty>
      <div class="secondary">
        {{t "contents.rates.season.rooms.empty"}}
      </div>
    </ta.empty>
  
  </UiTable>
  
  {{#if this.isDetailDialog}}
    <AcContents::Rates::SeasonRoomDialog
      @model={{this.activeRoom}}
      @rate={{this.rate}}
      @seasonId={{this.season.id}}
      @onClose={{fn (mut this.isDetailDialog) false}}
    />
  {{/if}}
  
  */
  {
    "id": "scV21LJq",
    "block": "[[[8,[39,0],[[4,[38,2],[[28,[37,1],[[30,0,[\"fetchRooms\"]]],null]],null],[4,[38,3],[[28,[37,1],[[30,0,[\"fetchRooms\"]]],null],[30,0,[\"season\",\"id\"]]],null]],[[\"@columns\",\"@rows\",\"@isLoading\",\"@formats\",\"@isError\",\"@onReload\",\"@classNames\"],[[30,0,[\"columns\"]],[30,0,[\"tableRows\"]],[30,0,[\"fetchRooms\",\"isRunning\"]],[30,0,[\"formats\"]],[30,0,[\"isError\"]],[28,[37,1],[[30,0,[\"fetchRooms\"]]],null],\"ma-auto\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"head\"]],null,null,null],[1,\"\\n  \"],[8,[30,1,[\"body\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"row\"]],null,[[\"@hasActions\",\"@buttonAction\"],[[28,[37,4],[[30,0,[\"_isViewer\"]]],null],[28,[37,5],null,[[\"icon\",\"action\"],[\"edit\",[30,0,[\"onRowClick\"]]]]]]],null],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\\n  \"],[8,[30,1,[\"empty\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"secondary\"],[12],[1,\"\\n      \"],[1,[28,[35,6],[\"contents.rates.season.rooms.empty\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n\"]],[1]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"isDetailDialog\"]],[[[1,\"  \"],[8,[39,8],null,[[\"@model\",\"@rate\",\"@seasonId\",\"@onClose\"],[[30,0,[\"activeRoom\"]],[30,0,[\"rate\"]],[30,0,[\"season\",\"id\"]],[28,[37,9],[[28,[37,10],[[30,0,[\"isDetailDialog\"]]],null],false],null]]],null],[1,\"\\n\"]],[]],null]],[\"ta\",\"body\"],false,[\"ui-table\",\"perform\",\"did-insert\",\"did-update\",\"not\",\"hash\",\"t\",\"if\",\"ac-contents/rates/season-room-dialog\",\"fn\",\"mut\"]]",
    "moduleName": "additive-content/components/ac-contents/rates/prices-table.hbs",
    "isStrictMode": false
  });
  /**
   * fetches and displays the prices per room of a season
   *
   * @class AcContentsRatesPricesTableComponent
   */
  let AcContentsRatesPricesTableComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.array | _propTypes.object), _dec3 = (0, _object.computed)('rooms.[]', 'rooms.@each.{noServicePrice,breakfastPrice,halfBoardPrice,fullBoardPrice,allInclusivePrice}', 'rate.mealTypes.[]'), _dec4 = (0, _emberConcurrency.task)(function* () {
    this.store.unloadAll('season-room');
    this.isError = false;
    try {
      this.rooms = yield this.store.findAll('season-room', {
        adapterOptions: {
          season: this.season.id
        }
      });

      // depending on the mealtypes of the rates the columns are set
      this.rate = yield (0, _rsvp.resolve)(this.season.rate);
      const columns = [{
        name: this.intl.t('contents.offers.offer.timespan.properties.rooms'),
        key: 'room'
      }];
      this.rate.mealTypes.forEach(type => columns.push({
        name: this.intl.t(`contents.rates.rate.properties.mealTypes.${type}`),
        key: type
      }));
      this.columns = columns;
    } catch (e) {
      this.isError = true;
    }
  }), (_class = class AcContentsRatesPricesTableComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      /**
       * the season model
       *
       * @argument season
       * @type {Object}
       */
      _initializerDefineProperty(this, "season", _descriptor4, this);
      /**
       * the raw table data
       *
       * @argument data
       * @type {Array|Object}
       */
      _initializerDefineProperty(this, "data", _descriptor5, this);
      _initializerDefineProperty(this, "columns", _descriptor6, this);
      _initializerDefineProperty(this, "rooms", _descriptor7, this);
      _initializerDefineProperty(this, "rate", _descriptor8, this);
      _initializerDefineProperty(this, "activeRoom", _descriptor9, this);
      _initializerDefineProperty(this, "isDetailDialog", _descriptor10, this);
      _initializerDefineProperty(this, "isError", _descriptor11, this);
      _defineProperty(this, "formats", {
        currency: {
          locale: this.currentUser.user.language || 'de',
          options: {
            currency: this.currentUser.currentOrganization.currency
          }
        }
      });
      _initializerDefineProperty(this, "fetchRooms", _descriptor12, this);
    }
    get tableRows() {
      var _this$rooms;
      return (_this$rooms = this.rooms) === null || _this$rooms === void 0 ? void 0 : _this$rooms.map(room => {
        var _this$rate;
        const row = [room.name];
        (_this$rate = this.rate) === null || _this$rate === void 0 || _this$rate.mealTypes.forEach(type => row.push({
          value: room[`${(0, _string.camelize)(type)}Price`] || undefined,
          format: 'currency'
        }));
        row.push(room.id);
        return row;
      });
    }
    get _isViewer() {
      return this.currentUser.hasReadOnlyContents;
    }
    /**
     * the edit action of the row
     *
     * @argument onRowClick
     * @type {Function}
     */
    onRowClick(row) {
      const room = this.rooms.find(room => room.id === row[row.length - 1]);
      this.activeRoom = room;
      this.isDetailDialog = true;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "season", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "data", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "columns", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "rooms", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "rate", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "activeRoom", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isDetailDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "tableRows", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "tableRows"), _class.prototype), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "fetchRooms", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onRowClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onRowClick"), _class.prototype)), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AcContentsRatesPricesTableComponent);
});