define("additive-content/components/ac-contents/offers/timespan-table", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-arg-types", "prop-types", "@ember/service"], function (_exports, _component, _templateFactory, _component2, _emberArgTypes, _propTypes, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <UiTable
    @columns={{this.columns}}
    @rows={{this.tableRows}}
    @formats={{this.formats}}
    @isLoading={{this.isLoading}}
    @classNames="ma-auto" as |ta|
  >
    <ta.head />
    <ta.body as |body|>
      <body.row @hasActions={{not this.isViewer}} @buttonAction={{hash icon="edit" action=this.onRowClick}} />
    </ta.body>
  </UiTable>
  
  */
  {
    "id": "QsCQ8HnP",
    "block": "[[[8,[39,0],null,[[\"@columns\",\"@rows\",\"@formats\",\"@isLoading\",\"@classNames\"],[[30,0,[\"columns\"]],[30,0,[\"tableRows\"]],[30,0,[\"formats\"]],[30,0,[\"isLoading\"]],\"ma-auto\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"head\"]],null,null,null],[1,\"\\n  \"],[8,[30,1,[\"body\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"row\"]],null,[[\"@hasActions\",\"@buttonAction\"],[[28,[37,1],[[30,0,[\"isViewer\"]]],null],[28,[37,2],null,[[\"icon\",\"action\"],[\"edit\",[30,0,[\"onRowClick\"]]]]]]],null],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"ta\",\"body\"],false,[\"ui-table\",\"not\",\"hash\"]]",
    "moduleName": "additive-content/components/ac-contents/offers/timespan-table.hbs",
    "isStrictMode": false
  });
  let AcContentsOffersPricesTableComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.bool), _dec2 = (0, _emberArgTypes.arg)(_propTypes.object), _dec3 = (0, _emberArgTypes.arg)(_propTypes.func), (_class = class AcContentsOffersPricesTableComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      /**
       * whether the data is being fetched
       *
       * @argument isLoading
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "isLoading", _descriptor3, this);
      /**
       * the timespan that should be displayed
       *
       * @argument timespan
       * @type {Object}
       */
      _initializerDefineProperty(this, "timespan", _descriptor4, this);
      _defineProperty(this, "columns", [{
        name: this.intl.t('contents.offers.offer.timespan.properties.timespan'),
        key: 'timespan'
      }, {
        name: this.intl.t('contents.offers.offer.timespan.properties.price'),
        key: 'amount',
        minWidth: '140px'
      }]);
      _defineProperty(this, "formats", {
        currency: {
          locale: this.currentUser.user.language || 'de',
          options: {
            currency: this.currentUser.currentOrganization.currency
          }
        }
      });
      /**
       * the edit action of the row
       *
       * @argument onRowClick
       * @type {Function}
       */
      _initializerDefineProperty(this, "onRowClick", _descriptor5, this);
    }
    get tableRows() {
      return this.timespan && [[this.timespan.name, {
        value: this.timespan.amount,
        format: 'currency'
      }]];
    }
    get isViewer() {
      return this.currentUser.hasReadOnlyContents;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "timespan", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "onRowClick", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  })), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AcContentsOffersPricesTableComponent);
});