define("additive-content/controllers/instance/contents/posts/index/post/comments", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@glimmer/tracking", "ember-concurrency", "date-fns"], function (_exports, _controller, _object, _service, _tracking, _emberConcurrency, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let InstanceContentsPostsIndexPostCommentsController = _exports.default = (_dec = (0, _object.computed)('comments', 'comments.length'), _dec2 = (0, _emberConcurrency.task)(function* () {
    try {
      this.comments = null;
      yield this.store.peekAll('comment').map(comment => comment.unloadRecord());
      this.comments = yield this.store.findAll('comment', {
        adapterOptions: {
          postId: this.model.id
        }
      });
    } catch (e) {
      this.uiToast.showToast({
        type: 'error',
        title: this.intl.t('global.toast.error.savedChanges')
      });
    }
  }), _dec3 = (0, _emberConcurrency.task)(function* () {
    try {
      var _people;
      let people = yield this.store.peekAll('person');
      if (!this.people.length) {
        people = yield this.store.findAll('person');
      }
      this.people = (_people = people) === null || _people === void 0 ? void 0 : _people.filter(e => e.publishedState !== 'error');
    } catch (e) {
      this.uiToast.showToast({
        type: 'error',
        title: this.intl.t('global.toast.error.savedChanges')
      });
    }
  }), (_class = class InstanceContentsPostsIndexPostCommentsController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor2, this);
      _initializerDefineProperty(this, "uiToast", _descriptor3, this);
      _initializerDefineProperty(this, "intl", _descriptor4, this);
      _initializerDefineProperty(this, "_activeComment", _descriptor5, this);
      _initializerDefineProperty(this, "_isEditDialogOpen", _descriptor6, this);
      _initializerDefineProperty(this, "_isRespondDialogOpen", _descriptor7, this);
      /**
       * whether all subcomments should be shown
       *
       * @property {Boolean} _showAllSubComments
       * @default false
       * @private
       */
      _initializerDefineProperty(this, "_showAllSubComments", _descriptor8, this);
      _initializerDefineProperty(this, "comments", _descriptor9, this);
      _initializerDefineProperty(this, "people", _descriptor10, this);
      _initializerDefineProperty(this, "fetchComments", _descriptor11, this);
      _initializerDefineProperty(this, "fetchPeople", _descriptor12, this);
    }
    get formattedComments() {
      var _this$comments;
      let formatted = [];
      if ((_this$comments = this.comments) !== null && _this$comments !== void 0 && _this$comments.length) {
        this.comments.forEach(comment => {
          if (!comment.parentId) {
            formatted.push(comment);
          }
        });
        this.comments.forEach(comment => {
          if (comment.parentId) {
            let parent = formatted.find(item => item.id === comment.parentId);
            if (parent) {
              if (!parent.subComments) {
                Object.assign(parent, {
                  subComments: []
                });
              }
              parent.subComments.push(comment);
              parent.subComments.sort((a, b) => {
                if ((0, _dateFns.isAfter)(a.createdAt, b.createdAt)) return 1;
                return -1;
              });
            }
          }
        });
      }
      return formatted;
    }
    openDeleteDialog(id) {
      try {
        this.uiDialog.showConfirm(this.intl.t('contents.posts.post.comments.deleteDialog.title'), this.intl.t('contents.posts.post.comments.deleteDialog.description'), async () => {
          const comment = await this.store.peekRecord('comment', id);
          await comment.destroyRecord({
            adapterOptions: {
              postId: comment.postId,
              commentId: comment.id
            }
          });
          await this.fetchComments.perform();
        }, this.intl.t('global.actions.delete'), true, true);
      } catch (error) {
        this.uiToast.showToast({
          type: 'error',
          title: this.intl.t('global.toast.error.savedChanges')
        });
      }
    }
    openEditDialog(id) {
      this._activeComment = this.store.peekRecord('comment', id);
      this._isEditDialogOpen = true;
    }
    openRespondDialog(id) {
      this._activeComment = this.store.peekRecord('comment', id);
      this._isRespondDialogOpen = true;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "_activeComment", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "_isEditDialogOpen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "_isRespondDialogOpen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "_showAllSubComments", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "comments", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "people", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "formattedComments", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "formattedComments"), _class.prototype), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "fetchComments", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "fetchPeople", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "openDeleteDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openDeleteDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openEditDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openEditDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openRespondDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openRespondDialog"), _class.prototype)), _class));
});