define("additive-content/components/ac-contents/detail/time-limited", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-arg-types", "prop-types", "date-fns"], function (_exports, _component, _templateFactory, _component2, _emberArgTypes, _propTypes, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.model.timeLimited}}
    <div class="ac-contents-detail__badge mr1 {{if this.model.language "ph1" ""}}">
      <div class="flex items-center br1 ba b--black-10 ph1 h1 font-sm secondary flex-grow-0">
        {{svg-jar "watch" width="24" height="24" class="flex-shrink-0 pr05"}}
        <span class="ellipsis">
          {{#if this.isSameDay}}
            {{t
              "contents.common.properties.timeLimitedSameDay"
              date=(date-format this.model.publicFrom format=this.dateFormat)
              from=(date-format this.model.publicFrom format="HH:mm")
              to=(date-format this.model.publicUntil format="HH:mm")
            }}
          {{else}}
            {{t
              "contents.common.properties.timeLimited"
              from=(date-format this.model.publicFrom format=this.dateFormat)
              to=(date-format this.model.publicUntil format=this.dateFormat)
            }}
          {{/if}}
        </span>
      </div>
    </div>
  {{/if}}
  */
  {
    "id": "cfIekDM7",
    "block": "[[[41,[30,0,[\"model\",\"timeLimited\"]],[[[1,\"  \"],[10,0],[15,0,[29,[\"ac-contents-detail__badge mr1 \",[52,[30,0,[\"model\",\"language\"]],\"ph1\",\"\"]]]],[12],[1,\"\\n    \"],[10,0],[14,0,\"flex items-center br1 ba b--black-10 ph1 h1 font-sm secondary flex-grow-0\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"watch\"],[[\"width\",\"height\",\"class\"],[\"24\",\"24\",\"flex-shrink-0 pr05\"]]]],[1,\"\\n      \"],[10,1],[14,0,\"ellipsis\"],[12],[1,\"\\n\"],[41,[30,0,[\"isSameDay\"]],[[[1,\"          \"],[1,[28,[35,2],[\"contents.common.properties.timeLimitedSameDay\"],[[\"date\",\"from\",\"to\"],[[28,[37,3],[[30,0,[\"model\",\"publicFrom\"]]],[[\"format\"],[[30,0,[\"dateFormat\"]]]]],[28,[37,3],[[30,0,[\"model\",\"publicFrom\"]]],[[\"format\"],[\"HH:mm\"]]],[28,[37,3],[[30,0,[\"model\",\"publicUntil\"]]],[[\"format\"],[\"HH:mm\"]]]]]]],[1,\"\\n\"]],[]],[[[1,\"          \"],[1,[28,[35,2],[\"contents.common.properties.timeLimited\"],[[\"from\",\"to\"],[[28,[37,3],[[30,0,[\"model\",\"publicFrom\"]]],[[\"format\"],[[30,0,[\"dateFormat\"]]]]],[28,[37,3],[[30,0,[\"model\",\"publicUntil\"]]],[[\"format\"],[[30,0,[\"dateFormat\"]]]]]]]]],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"svg-jar\",\"t\",\"date-format\"]]",
    "moduleName": "additive-content/components/ac-contents/detail/time-limited.hbs",
    "isStrictMode": false
  });
  let AcContentsDetailTimeLimitedComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), (_class = class AcContentsDetailTimeLimitedComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "model", _descriptor, this);
    }
    get isSameDay() {
      return (0, _dateFns.isSameDay)(this.model.publicFrom, this.model.publicUntil);
    }
    get dateFormat() {
      if ((0, _dateFns.isSameYear)(this.model.publicFrom, this.model.publicUntil)) {
        return 'dd. MMM';
      }
      return {
        de: 'dd.MM.yyyy',
        en: 'yyyy-MM-dd'
      };
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "model", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  })), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AcContentsDetailTimeLimitedComponent);
});