define("additive-content/services/ui-cd", ["exports", "@ember/service", "@glimmer/tracking", "ember-concurrency", "@additive-apps/utils/utils/colors", "@additive-apps/utils/utils/fonts", "additive-content/config/environment"], function (_exports, _service, _tracking, _emberConcurrency, _colors, _fonts, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let UiCdService = _exports.default = (_dec = (0, _emberConcurrency.task)(function* () {
    var _this$currentUser;
    let tasks = [];
    const currentOrganization = (_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 || (_this$currentUser = _this$currentUser.currentOrganization) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.id;
    if (this._lastFetchedOrganization === currentOrganization) {
      return;
    }
    const request = this.authenticatedFetch.fetch(`${_environment.default.APP.apiBaseHost}/${currentOrganization}/corporate-design`);
    tasks.push(request);
    tasks.push((0, _emberConcurrency.timeout)(500));
    const [response] = yield (0, _emberConcurrency.all)(tasks);
    if (!response || !response.ok) {
      return;
    }
    const {
      corporateDesign
    } = yield response.json();
    this.corporateDesignTypography = corporateDesign.typography;
    this.corporateDesignColors = corporateDesign.colors;
    this.fonts = corporateDesign.fonts;
    this.fontOptions = (0, _fonts.getFontOptions)(corporateDesign.fonts);
    this.colorOptions = (0, _colors.getColorOptions)(corporateDesign.colors);
    this._lastFetchedOrganization = currentOrganization;
  }), (_class = class UiCdService extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "fonts", _descriptor3, this);
      _initializerDefineProperty(this, "fontOptions", _descriptor4, this);
      _initializerDefineProperty(this, "colorOptions", _descriptor5, this);
      _initializerDefineProperty(this, "corporateDesignColors", _descriptor6, this);
      _initializerDefineProperty(this, "corporateDesignTypography", _descriptor7, this);
      _defineProperty(this, "_lastFetchedOrganization", null);
      _initializerDefineProperty(this, "fetchCorporateDesign", _descriptor8, this);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "fonts", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "fontOptions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "colorOptions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "corporateDesignColors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "corporateDesignTypography", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "fetchCorporateDesign", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
});