define("additive-content/controllers/instance/contents/offers/index/offer", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service"], function (_exports, _controller, _object, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let InstanceContentsOffersIndexOfferController = _exports.default = (_dec = (0, _computed.alias)('model.timespans'), _dec2 = (0, _object.computed)('currentUser.hasReadOnlyContents', 'model.publishedLanguages', 'timespans.@each.endsAt'), (_class = class InstanceContentsOffersIndexOfferController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor3, this);
      _initializerDefineProperty(this, "currentUser", _descriptor4, this);
      _initializerDefineProperty(this, "timespans", _descriptor5, this);
    }
    get showTimespanWarning() {
      var _this$timespans;
      if (this.currentUser.hasReadOnlyContents) {
        return false;
      }
      const {
        publishedLanguages
      } = this.model;
      if (!publishedLanguages || publishedLanguages.length === 0) {
        return false;
      }
      if (((_this$timespans = this.timespans) === null || _this$timespans === void 0 ? void 0 : _this$timespans.toArray().length) > 0) {
        let hasActiveTimespan = false;
        this.timespans.toArray().some(timespan => {
          if (timespan.endsAt && new Date().getTime() < timespan.endsAt.getTime()) {
            hasActiveTimespan = true;
            return true;
          }
        });
        return !hasActiveTimespan;
      }
      return true;
    }
    get minMaxAmount() {
      const {
        minAmount,
        maxAmount
      } = this.model;
      if (!minAmount || !maxAmount) {
        return null;
      }
      if (minAmount === maxAmount) {
        return this.intl.formatNumber(maxAmount, {
          format: this.uiAppSettings.currency
        });
      }
      const max = this.intl.formatNumber(maxAmount, {
        format: this.uiAppSettings.currency
      });
      const min = this.intl.formatNumber(minAmount, {
        format: this.uiAppSettings.currency
      });
      return `${min} - ${max}`;
    }
    transitionToCreateTimespan() {
      this.router.transitionTo('instance.contents.offers.index.offer.prices', {
        queryParams: {
          create: 1
        }
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "timespans", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "showTimespanWarning", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "showTimespanWarning"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "transitionToCreateTimespan", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToCreateTimespan"), _class.prototype)), _class));
});