define("additive-content/controllers/application", ["exports", "@ember/controller", "@glimmer/tracking", "@ember/service", "@ember/object", "@additive-apps/auth/utils/roles-map", "additive-content/utils/constants"], function (_exports, _controller, _tracking, _service, _object, _rolesMap, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  // add instance-routes here
  const instanceRoutes = [{
    path: 'instance.contents',
    intlKey: 'routes.contents.title',
    svg: 'overview'
  }, {
    path: 'instance.landing-pages',
    intlKey: 'routes.landingPages.title',
    svg: 'landing-page'
  }, {
    path: 'instance.styles',
    intlKey: 'routes.styles.title',
    svg: 'style'
  }, {
    path: 'instance.multimedia',
    intlKey: 'routes.multimedia.title',
    svg: 'image'
  }, {
    path: 'instance.auditlog',
    intlKey: 'routes.auditLog.title',
    svg: 'audit-log',
    requiredRole: 'member'
  }, {
    path: 'instance.settings',
    intlKey: 'routes.settings.title',
    svg: 'settings'
  }];
  let ApplicationController = _exports.default = (_class = class ApplicationController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "router", _descriptor4, this);
      _initializerDefineProperty(this, "session", _descriptor5, this);
      _initializerDefineProperty(this, "store", _descriptor6, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor7, this);
      _initializerDefineProperty(this, "uiLocale", _descriptor8, this);
      _initializerDefineProperty(this, "uiState", _descriptor9, this);
      _initializerDefineProperty(this, "uiToast", _descriptor10, this);
      _defineProperty(this, "queryParams", ['onboarding']);
      /**
       * whether onboarding is shown
       *
       * @property onboarding
       * @type {Boolean}
       * @default null
       */
      _initializerDefineProperty(this, "onboarding", _descriptor11, this);
    }
    get helpUrl() {
      var _this$uiLocale;
      const locale = ((_this$uiLocale = this.uiLocale) === null || _this$uiLocale === void 0 ? void 0 : _this$uiLocale.locale) || 'de';
      return `https://knowledge.additive.eu/${locale}/knowledge/additive-${locale === 'en' ? 'content' : 'inhalte'}`;
    }
    get isLandingPageRoutesActive() {
      var _this$currentUser;
      if ((_this$currentUser = this.currentUser) !== null && _this$currentUser !== void 0 && (_this$currentUser = _this$currentUser.currentOrganization) !== null && _this$currentUser !== void 0 && _this$currentUser.hasLandingPage) {
        return true;
      }
      return false;
    }
    get instanceRoutes() {
      return instanceRoutes.map(route => {
        var _this$router;
        return Object.assign({}, route, {
          isActive: ((_this$router = this.router) === null || _this$router === void 0 ? void 0 : _this$router.currentRouteName.indexOf(route.path)) === 0
        });
      }).filter(route => {
        var _this$currentUser2;
        if (['instance.landing-pages', 'instance.styles'].includes(route.path)) {
          return this.isLandingPageRoutesActive;
        }
        if (route.requiredRole && !(0, _rolesMap.hasAccess)(route.requiredRole, (_this$currentUser2 = this.currentUser) === null || _this$currentUser2 === void 0 || (_this$currentUser2 = _this$currentUser2.user) === null || _this$currentUser2 === void 0 ? void 0 : _this$currentUser2.role)) {
          return false;
        }
        return true;
      });
    }
    get onboardingOptions() {
      return (0, _constants.getOnBoarding)(this.intl);
    }
    transitionToOrganizationRoute(organization) {
      this.router.transitionTo('instance', organization.id);
      this.currentUser.set('currentOrganization', organization);
      this.currentUser.set('user', null);
      this.store.unloadAll('app');
      this.store.unloadAll('content-type');
      this.store.peekAll('comment').map(comment => comment.unloadRecord());
      this.store.unloadAll('daily-menu');
      this.store.unloadAll('event');
      this.store.unloadAll('frequently-asked-question');
      this.store.unloadAll('gallery');
      this.store.unloadAll('hotel-info');
      this.store.unloadAll('last-minute-offer');
      this.store.unloadAll('offer');
      this.store.unloadAll('page');
      this.store.unloadAll('person');
      this.store.unloadAll('point-of-interest');
      this.store.unloadAll('post');
      this.store.unloadAll('product');
      this.store.unloadAll('quote');
      this.store.unloadAll('room-service');
      this.store.unloadAll('room');
      this.store.unloadAll('season-room');
      this.store.unloadAll('season');
      this.store.unloadAll('timespan');
      this.store.unloadAll('timespan-room');
      this.store.unloadAll('tour');
      this.store.unloadAll('treatment');
      this.store.unloadAll('webcam');

      // unload rate after season
      this.store.unloadAll('rate');
      this.store.unloadAll('portal-score');

      // unload categories after content types to prevent reloading through unload
      this.store.unloadAll('category');

      // landing page related
      this.store.unloadAll('landing-page');
      this.store.unloadAll('style');

      // mm related models
      this.store.unloadAll('folder');
      this.store.unloadAll('medium');
      this.uiAppSettings.reset();
    }
    finishOnboarding() {
      this.onboarding = null;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "uiLocale", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "uiState", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "onboarding", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "transitionToOrganizationRoute", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToOrganizationRoute"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "finishOnboarding", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "finishOnboarding"), _class.prototype)), _class);
});