define("additive-content/controllers/instance/landing-pages/landing-page", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "@glimmer/tracking", "ember-concurrency", "ember-changeset", "ember-changeset-validations", "additive-content/utils/landing-page-utils", "additive-content/validations/landing-page"], function (_exports, _controller, _object, _computed, _service, _tracking, _emberConcurrency, _emberChangeset, _emberChangesetValidations, _landingPageUtils, _landingPage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let InstanceLandingPagesLandingPageController = _exports.default = (_dec = (0, _computed.alias)('currentUser.currentOrganization.id'), _dec2 = (0, _computed.alias)('uiAppSettings.currentLocale'), _dec3 = (0, _emberConcurrency.task)(function* () {
    if (!this.changeset) {
      return;
    }
    yield this.changeset.save();
  }), _dec4 = (0, _emberConcurrency.task)(function* () {
    yield this.model.reload();
    const validation = (0, _landingPage.landingPageValidation)(this.intl);
    this.changeset = new _emberChangeset.default(this.model, (0, _emberChangesetValidations.default)(validation), validation);
  }), (_class = class InstanceLandingPagesLandingPageController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor4, this);
      _initializerDefineProperty(this, "uiToast", _descriptor5, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor6, this);
      _initializerDefineProperty(this, "intl", _descriptor7, this);
      _initializerDefineProperty(this, "organizationId", _descriptor8, this);
      _initializerDefineProperty(this, "currentLanguage", _descriptor9, this);
      /**
       * the landing page changeset
       *
       * @argument changeset
       * @type {Object}
       */
      _initializerDefineProperty(this, "changeset", _descriptor10, this);
      /**
       * the internal model
       *
       * @property model
       * @type {Object}
       */
      _initializerDefineProperty(this, "model", _descriptor11, this);
      /**
       * whether the rename dialog is opened
       *
       * @property isRenameDialog
       * @type {Boolean}
       * @default false
       * @private
       */
      _initializerDefineProperty(this, "_isRenameDialog", _descriptor12, this);
      /**
       * whether the duplicate dialog is opened
       *
       * @property isDuplicateDialog
       * @type {Boolean}
       * @default false
       * @private
       */
      _initializerDefineProperty(this, "_isDuplicateDialog", _descriptor13, this);
      /**
       * whether the delete dialog is opened
       *
       * @property _isConfirmDeleteDialog
       * @type {Boolean}
       * @default false
       * @private
       */
      _initializerDefineProperty(this, "_isConfirmDeleteDialog", _descriptor14, this);
      /**
       * whether the publish dialog is opened
       *
       * @property isPublishDialog
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "isPublishDialog", _descriptor15, this);
      /**
       * the previous model id
       *
       * @property _previousModelId
       * @type {String}
       * @default null
       * @private
       */
      _initializerDefineProperty(this, "_previousModelId", _descriptor16, this);
      _defineProperty(this, "_deleteModel", _landingPageUtils.deleteLandingPage.task);
      _initializerDefineProperty(this, "save", _descriptor17, this);
      _initializerDefineProperty(this, "didChangeLanguage", _descriptor18, this);
    }
    /**
     * the published languages that are not valid anymore
     *
     * @computed _invalidPublishedLanguages
     * @type {Array}
     */
    get _invalidPublishedLanguages() {
      return this.model.publishedLanguages.filter(language => {
        return !this.model.publishableLanguages.includes(language);
      });
    }

    /**
     * whether the current user has role "viewer"
     *
     * @computed _isViewer
     * @type {Boolean}
     */
    get _isViewer() {
      return this.currentUser.hasReadOnlyLPsAndWidgets;
    }
    get _invalidPublishedLanguagesText() {
      const _invalidPublishedLanguagesTexts = this._invalidPublishedLanguages.map(language => {
        return `„${this.intl.t(`global.languages.${language}`)}“`;
      });
      if (_invalidPublishedLanguagesTexts.length === 1) {
        return _invalidPublishedLanguagesTexts[0];
      }
      const lastLanguage = _invalidPublishedLanguagesTexts.pop();
      return `${_invalidPublishedLanguagesTexts.join(', ')} ${this.intl.t('global.and')} ${lastLanguage}`;
    }
    didInsert() {
      const validation = (0, _landingPage.landingPageValidation)(this.intl);
      this.changeset = new _emberChangeset.default(this.model, (0, _emberChangesetValidations.default)(validation), validation);
    }
    back() {
      this.router.transitionTo('instance.landing-pages');
    }
    edit() {
      this.router.transitionTo('instance.landing-pages.landing-page.edit', this.model.id);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "organizationId", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "currentLanguage", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "changeset", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "model", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "_isRenameDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "_isDuplicateDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "_isConfirmDeleteDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "isPublishDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "_previousModelId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "save", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "didChangeLanguage", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "didInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "back", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "back"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "edit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "edit"), _class.prototype)), _class));
});