define("additive-content/controllers/instance/contents/rooms/index/create", ["exports", "@ember/controller", "@glimmer/tracking", "@ember/object", "@ember/service", "additive-content/utils/slug", "additive-content/utils/constants"], function (_exports, _controller, _tracking, _object, _service, _slug, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let InstanceContentsRoomsIndexCreateController = _exports.default = (_class = class InstanceContentsRoomsIndexCreateController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor2, this);
      _defineProperty(this, "priceInformationOptions", ['per_person', 'per_room']);
      _initializerDefineProperty(this, "validation", _descriptor3, this);
      _initializerDefineProperty(this, "isSlugSet", _descriptor4, this);
      _initializerDefineProperty(this, "isHelpDialog", _descriptor5, this);
    }
    get priceInformations() {
      return this.priceInformationOptions.map(key => ({
        value: key,
        name: this.intl.t(`contents.rooms.room.priceInformations.${key}`)
      }));
    }
    get roomTypes() {
      return _constants.ROOM_TYPES.map(type => ({
        value: type,
        name: this.intl.t(`contents.rooms.room.types.${type}`)
      }));
    }
    get labelSymbolTypes() {
      return _constants.LABEL_SYMBOL_TYPES.map(type => ({
        value: type,
        name: this.intl.t(`contents.common.labelSymbolTypes.${type}`)
      }));
    }
    onSlugChange(value) {
      this.isSlugSet = value ? true : false;
    }
    onLabelSymbolChange(changeset, symbol) {
      changeset.set('labelSymbol', symbol.value);
    }
    generateSlug(changeset, title) {
      // Do nothing if slug was set by the user
      if (this.isSlugSet) {
        return;
      }
      const slug = (0, _slug.stringToSlug)(title);
      changeset.set('slug', slug);
    }
    onOccupancyChange(changeset, field, value) {
      changeset.set(field, value);
      changeset.validate();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "validation", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isSlugSet", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isHelpDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onSlugChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onSlugChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onLabelSymbolChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onLabelSymbolChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "generateSlug", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "generateSlug"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onOccupancyChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onOccupancyChange"), _class.prototype)), _class);
});