define("additive-content/controllers/instance/contents/events/index/create", ["exports", "@ember/controller", "@glimmer/tracking", "@ember/object", "@ember/service", "date-fns"], function (_exports, _controller, _tracking, _object, _service, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let InstanceContentsEventsIndexCreateController = _exports.default = (_class = class InstanceContentsEventsIndexCreateController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "uiToast", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "validation", _descriptor3, this);
    }
    onChange(changeset, key, date) {
      date && changeset.set(key, new Date(date));
    }
    onTimeValidStateChange(changeset, key, isValid) {
      if (!isValid) {
        changeset.pushErrors(key, 'invalid');
      }
    }
    onSwitch(changeset, key, value) {
      changeset.set(key, value);
    }
    async onSave(_, changeset) {
      try {
        const response = await changeset.save();
        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });
        this.transitionToRoute('instance.contents.events.index.event', response.id, {
          queryParams: {
            dateFrom: (0, _dateFns.format)((0, _dateFns.startOfMonth)(response.startsAt), 'yyyy-MM-dd'),
            dateUntil: (0, _dateFns.format)((0, _dateFns.endOfMonth)(response.startsAt), 'yyyy-MM-dd')
          }
        });
      } catch (e) {
        this.uiToast.showToast({
          title: this.intl.t('toast.unexpectedError'),
          type: 'error'
        });
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "validation", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onTimeValidStateChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onTimeValidStateChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSwitch", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onSwitch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSave", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onSave"), _class.prototype)), _class);
});