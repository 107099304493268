define("additive-content/routes/instance/contents/offers/index/offer/prices", ["exports", "@ember/routing/route", "@ember/object", "@ember/service", "@additive-apps/ui/utils/dom-util"], function (_exports, _route, _object, _service, _domUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let InstanceContentsOffersIndexOfferPricesRoute = _exports.default = (_class = class InstanceContentsOffersIndexOfferPricesRoute extends _route.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _defineProperty(this, "controller", null);
    }
    model() {
      return this.modelFor('instance.contents.offers.index.offer');
    }
    setupController(controller, model) {
      var _model$timespans;
      this.controller = controller;
      controller.offerId = model.id;
      controller.timespans = model.timespans;
      controller.activeTimespan = (_model$timespans = model.timespans) === null || _model$timespans === void 0 ? void 0 : _model$timespans.firstObject;
      if (controller.activeTimespan) {
        var _controller$activeTim;
        controller.isPricePerRoom = !((_controller$activeTim = controller.activeTimespan) !== null && _controller$activeTim !== void 0 && _controller$activeTim.amount);
        controller.fetchTimespanRooms.perform();
      }
      if (this.controller.create) {
        this.handleQueryParam();
      }
    }

    /**
     * handles the create query param
     *
     * @function handleQueryParam
     */
    async handleQueryParam() {
      this.controller.isNewTimespanDialog = true;
      this.controller.create = undefined;
      await (0, _domUtil.nextTick)();

      // reset create query param
      this.router.transitionTo({
        queryParams: {
          create: undefined
        }
      });
    }
    queryParamsDidChange(queryParams) {
      if (this.controller && queryParams.create) {
        this.handleQueryParam();
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "queryParamsDidChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "queryParamsDidChange"), _class.prototype)), _class);
});