define("additive-content/routes/application", ["exports", "@ember/routing/route", "@additive-apps/auth/mixins/application-auth-route-mixin", "@ember/service", "ember-concurrency", "additive-content/config/environment"], function (_exports, _route, _applicationAuthRouteMixin, _service, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const isTest = _environment.default.environment === 'test';
  let ApplicationRoute = _exports.default = (_dec = (0, _emberConcurrency.task)(function* () {
    try {
      if (isTest) {
        return;
      }

      // check if the browser supports serviceWorker at all
      if ('serviceWorker' in navigator) {
        const registration = yield navigator.serviceWorker.getRegistration();

        // detect serviceWorker update available and wait for it to become installed
        registration.addEventListener('updatefound', () => {
          if (registration.installing) {
            // wait until the new serviceWorker is actually installed (ready to take over)
            registration.installing.addEventListener('statechange', () => {
              // if there's an existing controller (previous serviceWorker), show the update toast and clear the interval
              if (registration.waiting && navigator.serviceWorker.controller) {
                this.showUpdateToast();
              }
            });
          }
        });
      }
    } catch (e) {
      // don't display anything if this task fails, because it runs in the background
    }
  }).drop(), (_class = class ApplicationRoute extends _route.default.extend(_applicationAuthRouteMixin.default) {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "session", _descriptor4, this);
      _initializerDefineProperty(this, "store", _descriptor5, this);
      _initializerDefineProperty(this, "uiPaths", _descriptor6, this);
      _initializerDefineProperty(this, "uiLocale", _descriptor7, this);
      _initializerDefineProperty(this, "uiToast", _descriptor8, this);
      _defineProperty(this, "routeAfterAuthentication", 'instance');
      _defineProperty(this, "intervalId", null);
      _defineProperty(this, "isToastActive", false);
      _initializerDefineProperty(this, "updateServiceWorker", _descriptor9, this);
    }
    setupUpdateCheck() {
      if (isTest) {
        return;
      }
      const task = this.updateServiceWorker;
      // sets interval that checks for new SW registrations and updates those registrations
      // updating the registration will then trigger the installing and waiting events
      if (!this.intervalId) {
        this.intervalId = setInterval(function () {
          if ('serviceWorker' in navigator) {
            navigator.serviceWorker.ready.then(registration => {
              // eslint-disable-next-line
              registration.update();
              task.perform();
            });
          }
        }, 120000);
      }
    }
    showUpdateToast() {
      if (!this.isToastActive) {
        clearInterval(this.intervalId);
        const toast = this.uiToast.showToast({
          type: 'warning',
          title: this.intl.t('global.toasts.update.title'),
          description: this.intl.t('global.toasts.update.description'),
          actionLabel: this.intl.t('global.toasts.update.actionLabel'),
          duration: -1,
          icon: 'announcement',
          onClick: () => {
            this.uiToast.destroyToast(toast);
            this.isToastActive = false;
            this.applyUpdate();
          },
          onAbort: () => {
            this.uiToast.destroyToast(toast);
            this.isToastActive = false;
          }
        });
        this.isToastActive = true;
      }
    }
    applyUpdate() {
      // refresh the page; this causes the new serviceWorker to be applied.
      window.location.reload();
    }
    beforeModel() {
      this.uiLocale.setup();
      // update to api.<baseHost>.tech host format
      this.uiPaths.setApiRootKey('');
    }
    setupController() {
      super.setupController(...arguments);

      // checks if serviceWorker update is available once
      this.updateServiceWorker.perform();
      this.updateTitle();

      // sets up an interval of 120sec, where it checks again
      this.setupUpdateCheck();
    }
    updateTitle() {
      document.title = this.intl.t(`app.title`);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "uiPaths", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "uiLocale", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "updateServiceWorker", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
});