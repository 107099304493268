define("additive-content/utils/render-utils", ["exports", "@ember/string", "lodash.clonedeep", "lodash.merge"], function (_exports, _string, _lodash, _lodash2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unlazyImages = _exports.toKeyValue = _exports.snakeCasePropertyKeys = _exports.getTypography = _exports.getColors = _exports.formatBlock = _exports.decamelizeKeysDeep = _exports.STYLE_PREVIEW_BLOCKS = _exports.BLOCK_CONTENT = void 0;
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /**
   * helper function to change all object keys to snake_case
   *
   * @param {Object} object
   * @param {Object} result
   * @returns {Object}
   */
  const snakeCasePropertyKeys = _exports.snakeCasePropertyKeys = function snakeCasePropertyKeys(object) {
    let result = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    if (Array.isArray(object)) {
      result = [];
      object.forEach(entry => {
        result.push(snakeCasePropertyKeys(entry, {}));
      });
      return result;
    } else if (typeof object === 'object') {
      Object.keys(object).forEach(key => {
        result[(0, _string.underscore)(key)] = object[key] && typeof object[key] === 'object' ? snakeCasePropertyKeys(object[key], result[(0, _string.underscore)(key)]) : object[key];
      });
    } else {
      return object;
    }
    return result;
  };

  /**
   * gets the correct value of a property considering sync, corporate design and style
   *
   * @param {Object} property
   * @param {Object} cd
   * @param {Object} style
   * @returns {Object}
   */
  const toKeyValue = (property, cd, style) => {
    const newObject = {};
    property.forEach(styleGroup => {
      styleGroup.properties.forEach(property => {
        if (property.sync) {
          if (property.type === 'typography') {
            var _style$styles;
            if ((style === null || style === void 0 || (_style$styles = style.styles) === null || _style$styles === void 0 || (_style$styles = _style$styles.typography) === null || _style$styles === void 0 || (_style$styles = _style$styles[property.syncedValue]) === null || _style$styles === void 0 ? void 0 : _style$styles.sync) === false) {
              const fontData = (0, _lodash.default)(style.styles.typography[property.syncedValue]);
              delete fontData.sync;
              delete fontData.syncedTypography;
              newObject[property.id] = fontData;
            } else {
              newObject[property.id] = cd.corporateDesignTypography[property.syncedValue];
            }
          } else {
            newObject[property.id] = property.syncedValue || typeof property.syncedValue === 'boolean' ? property.syncedValue : null;
          }
        } else {
          newObject[property.id] = property.value || typeof property.value === 'boolean' ? property.value : null;
        }
      });
    });
    return newObject;
  };

  /**
   * transforms properties into snake_case since the
   * render service expects this format
   *
   * @function decamelizeKeysDeep
   */
  _exports.toKeyValue = toKeyValue;
  const decamelizeKeysDeep = obj => {
    if (!obj) {
      return obj;
    }
    if (obj instanceof Date) {
      return obj;
    }
    if (Array.isArray(obj)) {
      return obj.map(element => {
        return decamelizeKeysDeep(element);
      });
    }
    if (typeof obj === 'object') {
      Object.keys(obj).forEach(key => {
        const newKey = (0, _string.decamelize)(key);
        obj[newKey] = decamelizeKeysDeep(obj[key]);
        if (key !== newKey) delete obj[key];
      });
    }
    return obj;
  };

  /**
   * Replaces synced colors with the correct color values
   *
   * @function getColors
   */
  _exports.decamelizeKeysDeep = decamelizeKeysDeep;
  const getColors = styleColors => {
    const colors = {};
    Object.keys(styleColors).forEach(key => {
      const color = styleColors[key];
      if (color.sync) {
        colors[key] = {
          color: color.syncedColor,
          contrastColor: color.syncedContrastColor
        };
      } else {
        colors[key] = {
          color: color.color,
          contrastColor: color.contrastColor
        };
      }
    });
    return colors;
  };

  /**
   * Replaces synced typographies with the correct font values
   *
   * @function getTypography
   */
  _exports.getColors = getColors;
  const getTypography = (typography, colors, cd) => {
    const typographies = {};
    Object.keys(typography).forEach(key => {
      const typo = typography[key];
      if (typo.sync) {
        typographies[key] = _objectSpread({}, cd.corporateDesignTypography[typo.syncedTypography]);
        if (_isCustomFont(typographies[key].fontFamily)) {
          const [fontKey, weight] = typographies[key].fontFamily.split('.');
          typographies[key].fontFamily = cd.fonts[fontKey][weight].fontFamily;
        }
      } else {
        typographies[key] = typo;
      }
      if (Object.keys(colors).indexOf(typographies[key].color) >= 0) {
        typographies[key].color = colors[typographies[key].color];
      }
    });
    return typographies;
  };

  /**
   * Formats a block by replacing color, typography, button and synced values into the format that the
   * render service expects
   *
   * @function formatBlock
   */
  _exports.getTypography = getTypography;
  const formatBlock = (block, typography, colors, cd, styleModel) => {
    var _block$style, _BLOCK_CONTENT$block$;
    const styles = {};
    (_block$style = block.style) === null || _block$style === void 0 || _block$style.forEach(style => {
      var _style$properties;
      (_style$properties = style.properties) === null || _style$properties === void 0 || _style$properties.forEach(property => {
        if (property.type === 'typography') {
          if (property.sync) {
            styles[property.id] = typography === null || typography === void 0 ? void 0 : typography[property.syncedValue];
          } else {
            const font = _objectSpread({}, property.value);
            if (_isCustomFont(font.fontFamily)) {
              var _cd$fonts;
              const [fontKey, weight] = font.fontFamily.split('.');
              if ((_cd$fonts = cd.fonts) !== null && _cd$fonts !== void 0 && (_cd$fonts = _cd$fonts[fontKey]) !== null && _cd$fonts !== void 0 && _cd$fonts[weight]) {
                font.fontFamily = cd.fonts[fontKey][weight].fontFamily;
              } else {
                font.fontFamily = font.serif ? 'serif' : 'sans-serif';
              }
            }
            if (typeof font.color === 'string') {
              font.color = colors[font.color];
            }
            styles[property.id] = font;
          }
          /*
            CD fallback font families contain `"` quotes which mess up inline styles
            TODO: check if this can be fixed globally by the API
          */
          styles[property.id].fontFamily = styles[property.id].fontFamily.replace(/"/g, "'");
        } else if (property.type === 'color') {
          if (property.sync) {
            if (typeof property.syncedValue === 'string') {
              styles[property.id] = colors[property.syncedValue];
            } else {
              styles[property.id] = property.syncedValue;
            }
          } else if (typeof property.value === 'string') {
            styles[property.id] = colors[property.value];
          } else {
            styles[property.id] = property.value;
          }
        } else if (property.sync) {
          var _styleModel$styles;
          // use synced value from style if it is a global property
          styles[property.id] = (styleModel === null || styleModel === void 0 || (_styleModel$styles = styleModel.styles) === null || _styleModel$styles === void 0 || (_styleModel$styles = _styleModel$styles.globals) === null || _styleModel$styles === void 0 ? void 0 : _styleModel$styles[(0, _string.camelize)(property.id)]) || property.syncedValue;
        } else {
          styles[property.id] = property.value;
        }
      });
    });
    block.style = styles;
    const styledBlock = decamelizeKeysDeep((0, _lodash2.default)({}, block));
    styledBlock.content = (0, _lodash2.default)({}, (_BLOCK_CONTENT$block$ = BLOCK_CONTENT[block.blockIdentifier]) === null || _BLOCK_CONTENT$block$ === void 0 ? void 0 : _BLOCK_CONTENT$block$[block.variationIdentifier]);
    return styledBlock;
  };

  /**
   * Makes lazy loaded images in landing page html unlazy
   *
   * @function unlazyImages
   */
  _exports.formatBlock = formatBlock;
  const unlazyImages = html => {
    if (!html) return;
    const lazyImages = html.querySelectorAll('.lazy-image');
    for (const lazyImage of lazyImages) {
      lazyImage.classList.remove('lazy-image');
    }
    const imagesWithDataSrc = html.querySelectorAll('img[data-src]');
    for (const imageWithDataSrc of imagesWithDataSrc) {
      imageWithDataSrc.src = imageWithDataSrc.dataset.src;
      delete imageWithDataSrc.dataset.src;
    }
    const sourcesWithDataSrcset = html.querySelectorAll('source[data-srcset]');
    for (const sourceWithDataSrcset of sourcesWithDataSrcset) {
      sourceWithDataSrcset.srcset = sourceWithDataSrcset.dataset.srcset;
      delete sourceWithDataSrcset.dataset.srcset;
    }
    return html;
  };
  _exports.unlazyImages = unlazyImages;
  const _isCustomFont = fontFamily => fontFamily.startsWith('primary') || fontFamily.startsWith('secondary');
  const STYLE_PREVIEW_BLOCKS = _exports.STYLE_PREVIEW_BLOCKS = ['header.simple', 'text.columns', 'form.framed', 'footer.simple'];

  /**
   * Temporary block contents.
   * TODO: These contets should be provided by the API
   */
  const BLOCK_CONTENT = _exports.BLOCK_CONTENT = {
    footer: {
      simple: {
        title: 'Company name',
        description: '<p>Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.</p>',
        privacy: 'Privacy',
        disclaimer: 'Disclaimer'
      }
    },
    header: {
      small: {
        logo: {
          url: 'https://additive-content.s3.eu-central-1.amazonaws.com/assets/images/placeholder_logo.png',
          width: 160,
          height: 160
        }
      },
      simple: {
        text: '<h1>Lorem ipsum</h1><br><p>Lorem ipsum</p>',
        logo: {
          url: 'https://additive-content.s3.eu-central-1.amazonaws.com/assets/images/placeholder_logo.png',
          width: 160,
          height: 160
        },
        background_image: {
          url: 'https://additive-content.s3.eu-central-1.amazonaws.com/assets/images/placeholder_image_1.jpg',
          width: 600,
          height: 480
        }
      },
      columns: {
        heading: '<h1>Lorem ipsum</h1>',
        text: '<p>Lorem ipsum</p>',
        logo: {
          url: 'https://additive-content.s3.eu-central-1.amazonaws.com/assets/images/placeholder_logo.png',
          width: 160,
          height: 160
        },
        background_image: {
          url: 'https://additive-content.s3.eu-central-1.amazonaws.com/assets/images/placeholder_image_1.jpg',
          width: 600,
          height: 480
        }
      }
    },
    spacer: {
      small: {},
      large: {}
    },
    text: {
      simple: {
        text: `
        <h1>At vero eos</h1>
        <br/>
        <br/>
        <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.</p>
      `
      },
      columns: {
        heading: '<h1>At vero eos</h1>',
        text: '<p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.</p>'
      }
    },
    voucher: {
      simple: {
        voucher_widget: {
          selector: 'widget-v2',
          url: 'https://voucher.additive-apps.tech/testhotel-post-com/widgets/394ef357-137a-4f32-8e0e-11427e8c00db/de'
        }
      }
    },
    form: {
      framed: {
        submit: 'Unverbindlich anfragen'
      }
    }
  };
});