define("additive-content/components/ac-widgets/ac-widget-configuration/tracking", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PanelGroup @title={{t "configurator.tracking.title"}} as |group|>
    <group.item @label={{t "configurator.tracking.google"}} @isDisabled={{true}}>
      <UiSwitch
        @value={{this.changeset.tracking.googleAnalytics}}
        @isReadOnly={{this.isReadOnly}}
        @onChange={{fn (mut this.changeset.tracking.googleAnalytics)}}
      />
    </group.item>
    <group.item @label={{t "configurator.tracking.facebook"}} @isDisabled={{true}}>
      <UiSwitch
        @value={{this.changeset.tracking.facebook}}
        @isReadOnly={{this.isReadOnly}}
        @onChange={{fn (mut this.changeset.tracking.facebook)}}
      />
    </group.item>
    <div class="font-sm secondary pt2">
      {{t "configurator.helpTexts.tracking.description"}}
    </div>
  </PanelGroup>
  */
  {
    "id": "e46og2Qw",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"configurator.tracking.title\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"item\"]],null,[[\"@label\",\"@isDisabled\"],[[28,[37,1],[\"configurator.tracking.google\"],null],true]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@value\",\"@isReadOnly\",\"@onChange\"],[[30,0,[\"changeset\",\"tracking\",\"googleAnalytics\"]],[30,0,[\"isReadOnly\"]],[28,[37,3],[[28,[37,4],[[30,0,[\"changeset\",\"tracking\",\"googleAnalytics\"]]],null]],null]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[30,1,[\"item\"]],null,[[\"@label\",\"@isDisabled\"],[[28,[37,1],[\"configurator.tracking.facebook\"],null],true]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@value\",\"@isReadOnly\",\"@onChange\"],[[30,0,[\"changeset\",\"tracking\",\"facebook\"]],[30,0,[\"isReadOnly\"]],[28,[37,3],[[28,[37,4],[[30,0,[\"changeset\",\"tracking\",\"facebook\"]]],null]],null]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[10,0],[14,0,\"font-sm secondary pt2\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[\"configurator.helpTexts.tracking.description\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]]]]]],[\"group\"],false,[\"panel-group\",\"t\",\"ui-switch\",\"fn\",\"mut\"]]",
    "moduleName": "additive-content/components/ac-widgets/ac-widget-configuration/tracking.hbs",
    "isStrictMode": false
  });
  let AcWidgetConfigurationTracking = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.boolean), (_class = class AcWidgetConfigurationTracking extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "changeset", _descriptor, this);
      _initializerDefineProperty(this, "isReadOnly", _descriptor2, this);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "changeset", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isReadOnly", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  })), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AcWidgetConfigurationTracking);
});