define("additive-content/components/ac-landing-pages/configurator/content-block", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/runloop", "@ember/template", "ember-arg-types", "prop-types", "@additive-apps/ui/utils/dom-util", "additive-content/utils/render-utils"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _runloop, _template, _emberArgTypes, _propTypes, _domUtil, _renderUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="ac-landing-pages-configurator-content-block relative w-100 flex flex-column items-center
  
  
      {{if this._isDragged "is-dragged" ""}}"
    draggable={{not @isReadOnly}}
    {{did-insert this._onInsertElement}}
  >
    {{this._html}}
    <AcLandingPages::Configurator::ActionWrapper
      @contentBlock={{this.block}}
      @hasActions={{this.hasActions}}
      @isActive={{@isActive}}
      @isReadOnly={{@isReadOnly}}
      @position={{@position}}
      @onChangeVariation={{@onChangeVariation}}
      @onClick={{@onClickContentBlock}}
      @onClickOutside={{@onClickOutside}}
      @onDelete={{@onDelete}}
      @onDuplicate={{@onDuplicate}}
    />
  </div>
  */
  {
    "id": "JVUkC4ky",
    "block": "[[[11,0],[16,0,[29,[\"ac-landing-pages-configurator-content-block relative w-100 flex flex-column items-center\\n\\n\\n    \",[52,[30,0,[\"_isDragged\"]],\"is-dragged\",\"\"]]]],[16,\"draggable\",[28,[37,1],[[30,1]],null]],[4,[38,2],[[30,0,[\"_onInsertElement\"]]],null],[12],[1,\"\\n  \"],[1,[30,0,[\"_html\"]]],[1,\"\\n  \"],[8,[39,3],null,[[\"@contentBlock\",\"@hasActions\",\"@isActive\",\"@isReadOnly\",\"@position\",\"@onChangeVariation\",\"@onClick\",\"@onClickOutside\",\"@onDelete\",\"@onDuplicate\"],[[30,0,[\"block\"]],[30,0,[\"hasActions\"]],[30,2],[30,1],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8]]],null],[1,\"\\n\"],[13]],[\"@isReadOnly\",\"@isActive\",\"@position\",\"@onChangeVariation\",\"@onClickContentBlock\",\"@onClickOutside\",\"@onDelete\",\"@onDuplicate\"],false,[\"if\",\"not\",\"did-insert\",\"ac-landing-pages/configurator/action-wrapper\"]]",
    "moduleName": "additive-content/components/ac-landing-pages/configurator/content-block.hbs",
    "isStrictMode": false
  });
  /**
   * Loads and renders a content block
   *
   * @module ac-landing-pages
   * @class AcLandingPagesConfiguratorContentBlockComponent
   */
  let AcLandingPagesConfiguratorContentBlockComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.object), _dec3 = (0, _emberArgTypes.arg)(_propTypes.number), _dec4 = (0, _emberArgTypes.arg)(_propTypes.func), _dec5 = (0, _emberArgTypes.arg)(_propTypes.func), _dec6 = (0, _emberArgTypes.arg)(_propTypes.func), _dec7 = (0, _emberArgTypes.arg)(_propTypes.func), (_class = class AcLandingPagesConfiguratorContentBlockComponent extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * the content of this block
       *
       * @argument block
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "block", _descriptor, this);
      /**
       * the landing page model
       *
       * @argument landingPageModel
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "landingPageModel", _descriptor2, this);
      /**
       * the position of this block
       *
       * @argument position
       * @type {Number}
       * @default null
       */
      _initializerDefineProperty(this, "position", _descriptor3, this);
      /**
       * the main HTML element of this component
       *
       * @property _element
       * @type {HTMLElement}
       * @default null
       * @private
       */
      _defineProperty(this, "_element", null);
      /**
       * the formatted HTML
       *
       * @property _html
       * @type {String}
       * @default null
       * @private
       */
      _initializerDefineProperty(this, "_html", _descriptor4, this);
      /**
       * whether the block is being dragged
       *
       * @property _isDragged
       * @type {Boolean}
       * @default false
       * @private
       */
      _initializerDefineProperty(this, "_isDragged", _descriptor5, this);
      /**
       * whether the block has picture tags
       * @property _hasPictureTags
       * @type {Boolean}
       * @default false
       * @private
       */
      _initializerDefineProperty(this, "_hasPictureTags", _descriptor6, this);
      /**
       * the callback triggered on delete
       *
       * @argument onDelete
       * @type {Function}
       */
      _initializerDefineProperty(this, "onDelete", _descriptor7, this);
      /**
       * the callback triggered on drag start
       *
       * @argument onDragStart
       * @type {Function}
       */
      _initializerDefineProperty(this, "onDragStart", _descriptor8, this);
      /**
       * the callback triggered on drag end
       *
       * @argument onDragStart
       * @type {Function}
       */
      _initializerDefineProperty(this, "onDragEnd", _descriptor9, this);
      /**
       * the callback triggered on duplicate
       *
       * @argument onDuplicate
       * @type {Function}
       */
      _initializerDefineProperty(this, "onDuplicate", _descriptor10, this);
      /**
       * if the block has picture tags, this function will resize the images
       * manually removes the media attribute from sources that are not appropriate for the current block width
       * because the source media attribute refers to the window size and not the block size
       */
      _defineProperty(this, "_onResize", () => {
        let foundAppropriateMaxWidth = false;
        const blockWidth = this._element.offsetWidth;
        const pictureTags = this._element.querySelectorAll('picture');
        pictureTags.forEach(pictureTag => {
          const sources = pictureTag.querySelectorAll('source');
          sources.forEach(source => {
            var _source$dataset$media;
            // save the original media attribute to the dataset
            source.media && (source.dataset.media = source.media);
            if ((_source$dataset$media = source.dataset.media) !== null && _source$dataset$media !== void 0 && _source$dataset$media.includes('max-width')) {
              const sourceMaxWidth = Number(source.dataset.media.split('max-width: ')[1].split('px')[0]);
              if (blockWidth > sourceMaxWidth || blockWidth <= sourceMaxWidth && !foundAppropriateMaxWidth) {
                source.media = source.dataset.media;
                if (blockWidth <= sourceMaxWidth) {
                  foundAppropriateMaxWidth = true;
                }
              } else {
                source.media = '';
              }
            }
          });
        });
      });
    }
    /**
     * whether the block has actions
     *
     * @computed hasActions
     * @type {Boolean}
     */
    get hasActions() {
      var _this$block, _this$block2, _this$block3, _this$block4, _this$block5, _this$block6;
      if (['header', 'marketing_automation', 'footer'].includes((_this$block = this.block) === null || _this$block === void 0 || (_this$block = _this$block.model) === null || _this$block === void 0 ? void 0 : _this$block.blockIdentifier)) {
        return false;
      }
      if (((_this$block2 = this.block) === null || _this$block2 === void 0 || (_this$block2 = _this$block2.model) === null || _this$block2 === void 0 ? void 0 : _this$block2.blockIdentifier) === 'voucher' && this.landingPageModel.type === 'voucher') {
        return false;
      }
      if (((_this$block3 = this.block) === null || _this$block3 === void 0 || (_this$block3 = _this$block3.model) === null || _this$block3 === void 0 ? void 0 : _this$block3.blockIdentifier) === 'newsletter' && this.landingPageModel.type === 'newsletter') {
        return false;
      }
      if (((_this$block4 = this.block) === null || _this$block4 === void 0 || (_this$block4 = _this$block4.model) === null || _this$block4 === void 0 ? void 0 : _this$block4.blockIdentifier) === 'referral' && (this.landingPageModel.type === 'referral_subscriber' || this.landingPageModel.type === 'referral_referrer')) {
        return false;
      }
      if (((_this$block5 = this.block) === null || _this$block5 === void 0 || (_this$block5 = _this$block5.model) === null || _this$block5 === void 0 ? void 0 : _this$block5.blockIdentifier) === 'subscription' && this.landingPageModel.type === 'subscription') {
        return false;
      }
      if (((_this$block6 = this.block) === null || _this$block6 === void 0 || (_this$block6 = _this$block6.model) === null || _this$block6 === void 0 ? void 0 : _this$block6.blockIdentifier) === 'survey' && this.landingPageModel.type === 'survey') {
        return false;
      }
      return true;
    }
    _onDragStart() {
      this.onDragStart(this._element);
      (0, _runloop.next)(this, () => {
        if (!this.isDestroying) {
          this._isDragged = true;
        }
      });
    }
    _onDragEnd() {
      this.onDragEnd();
      (0, _runloop.next)(this, () => {
        if (!this.isDestroying) {
          this._isDragged = false;
        }
      });
    }
    async _onInsertElement(element) {
      // setup drag-events for sorting
      this._onDragStart = (0, _runloop.bind)(this, this._onDragStart);
      this._onDragEnd = (0, _runloop.bind)(this, this._onDragEnd);
      this._onResize = (0, _runloop.bind)(this, this._onResize);
      if (element) {
        var _this$block7;
        // drag events
        this._element = element;
        this._element.addEventListener('dragstart', this._onDragStart, false);
        this._element.addEventListener('dragend', this._onDragEnd, false);
        if ((_this$block7 = this.block) !== null && _this$block7 !== void 0 && _this$block7.html) {
          const parser = new DOMParser();
          let layout = parser.parseFromString(this.block.html, 'text/html');
          const scriptTags = layout === null || layout === void 0 ? void 0 : layout.getElementsByTagName('script');
          for (const scriptTag of scriptTags) {
            // load only ADDITIVE scripts in configurator
            if (scriptTag.src.indexOf('additive-apps.tech') >= 0) {
              // hack to trigger loading of scripts as they don't load otherwise
              const script = document.createElement('script');
              script.type = 'text/javascript';
              script.src = scriptTag.src;
              element.append(script);
            }
            scriptTag.remove();
          }
          (0, _renderUtils.unlazyImages)(layout);
          this._html = (0, _template.htmlSafe)(layout.body.innerHTML);

          // check if there are picture tags
          layout.getElementsByTagName('picture').length > 0 ? this._hasPictureTags = true : this._hasPictureTags = false;
          if (this._hasPictureTags) {
            await (0, _domUtil.nextTick)();
            new ResizeObserver(this._onResize).observe(this._element);
            this._onResize();
          }
        }
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "block", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "landingPageModel", [_dec2, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "position", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "_html", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "_isDragged", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "_hasPictureTags", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "onDelete", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "onDragStart", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "onDragEnd", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "onDuplicate", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_onInsertElement", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_onInsertElement"), _class.prototype)), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AcLandingPagesConfiguratorContentBlockComponent);
});