define("additive-content/components/ac-publish-dialog/publish-dialog/general-validations", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/service", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _tracking, _service, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <table class="ui-table ac-publish-dialog__table font-sm mt3">
    <tbody>
      <tr class="secondary bb b--secondary-10">
        <td class="pl3 pv2">
          {{t "components.acPublishDialog.publishDialog.generalValidations.header"}}
        </td>
        <td></td>
      </tr>
      {{#each this.formattedValidations as |formattedValidation|}}
        <tr class="bb b--secondary-10">
          <td class="pl3 pv2 flex flex-column items-start">
            {{formattedValidation.name}}
            <div class="secondary db pt1 pt0-sm dn-sm">
              {{#each formattedValidation.validations as |validation|}}
                <div class="flex items-start">
                  <UiStatus @classNames="mr1" @state={{validation.type}} />
                  {{validation.message}}
                </div>
              {{/each}}
            </div>
          </td>
          <td class="pa2 secondary">
            <div class="dn db-sm">
              {{#each formattedValidation.validations as |validation|}}
                <div class="flex items-start">
                  <UiStatus @classNames="mr1" @state={{validation.type}} />
                  {{validation.message}}
                </div>
              {{/each}}
            </div>
          </td>
        </tr>
      {{/each}}
    </tbody>
  </table>
  */
  {
    "id": "BRS8RL9S",
    "block": "[[[10,\"table\"],[14,0,\"ui-table ac-publish-dialog__table font-sm mt3\"],[12],[1,\"\\n  \"],[10,\"tbody\"],[12],[1,\"\\n    \"],[10,\"tr\"],[14,0,\"secondary bb b--secondary-10\"],[12],[1,\"\\n      \"],[10,\"td\"],[14,0,\"pl3 pv2\"],[12],[1,\"\\n        \"],[1,[28,[35,0],[\"components.acPublishDialog.publishDialog.generalValidations.header\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"td\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"formattedValidations\"]]],null]],null],null,[[[1,\"      \"],[10,\"tr\"],[14,0,\"bb b--secondary-10\"],[12],[1,\"\\n        \"],[10,\"td\"],[14,0,\"pl3 pv2 flex flex-column items-start\"],[12],[1,\"\\n          \"],[1,[30,1,[\"name\"]]],[1,\"\\n          \"],[10,0],[14,0,\"secondary db pt1 pt0-sm dn-sm\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1,[\"validations\"]]],null]],null],null,[[[1,\"              \"],[10,0],[14,0,\"flex items-start\"],[12],[1,\"\\n                \"],[8,[39,3],null,[[\"@classNames\",\"@state\"],[\"mr1\",[30,2,[\"type\"]]]],null],[1,\"\\n                \"],[1,[30,2,[\"message\"]]],[1,\"\\n              \"],[13],[1,\"\\n\"]],[2]],null],[1,\"          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"td\"],[14,0,\"pa2 secondary\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"dn db-sm\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1,[\"validations\"]]],null]],null],null,[[[1,\"              \"],[10,0],[14,0,\"flex items-start\"],[12],[1,\"\\n                \"],[8,[39,3],null,[[\"@classNames\",\"@state\"],[\"mr1\",[30,3,[\"type\"]]]],null],[1,\"\\n                \"],[1,[30,3,[\"message\"]]],[1,\"\\n              \"],[13],[1,\"\\n\"]],[3]],null],[1,\"          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"formattedValidation\",\"validation\",\"validation\"],false,[\"t\",\"each\",\"-track-array\",\"ui-status\"]]",
    "moduleName": "additive-content/components/ac-publish-dialog/publish-dialog/general-validations.hbs",
    "isStrictMode": false
  });
  let AcPublishDialogPublishDialogGeneralValidationsComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), (_class = class AcPublishDialogPublishDialogGeneralValidationsComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "validations", _descriptor2, this);
    }
    get formattedValidations() {
      if (!this.validations) {
        return [];
      }
      const formattedValidations = [];
      Object.keys(this.validations).forEach(validationKey => {
        formattedValidations.push(this.validations[validationKey]);
      });
      return formattedValidations;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "validations", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  })), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AcPublishDialogPublishDialogGeneralValidationsComponent);
});