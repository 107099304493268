define("additive-content/controllers/instance/contents/offers/index/offer/prices", ["exports", "@ember/object", "@ember/controller", "@ember/service", "@glimmer/tracking", "@ember/object/computed", "ember-concurrency", "@ember/template"], function (_exports, _object, _controller, _service, _tracking, _computed, _emberConcurrency, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let InstanceContentsOffersIndexOfferPricesController = _exports.default = (_dec = (0, _computed.or)('fetchTimespans.isRunning', 'fetchTimespanRooms.isRunning'), _dec2 = (0, _computed.not)('activeTimespan.amount'), _dec3 = (0, _emberConcurrency.task)(function* () {
    if (this.isPricePerRoom) {
      this.isPricePerRoom = false;
      this.isEditTimespanDialog = true;
    } else {
      (0, _object.set)(this, 'activeTimespan.amount', null);
      yield this.activeTimespan.save(this.adapterOptions);
      this.fetchTimespans.perform();
    }
  }), _dec4 = (0, _emberConcurrency.task)(function* (timespan) {
    const tasks = [timespan.destroyRecord(this.adapterOptions), (0, _emberConcurrency.timeout)(400)];
    yield (0, _emberConcurrency.all)(tasks);
    if (timespan.title === this.activeTimespan.title) {
      this.activeTimespan = null;
    }
    this.fetchTimespans.perform();
  }), _dec5 = (0, _emberConcurrency.task)(function* (timespanId) {
    var _this$activeTimespan;
    this.timespans = [...this.store.peekAll('timespan', this.adapterOptions).toArray().filter(timespan => {
      return timespan.offer.id === this.offerId;
    })];
    if (!this.timespans.length) {
      const tasks = [this.store.findAll('timespan', this.adapterOptions), (0, _emberConcurrency.timeout)(400)];
      const [timespans] = yield (0, _emberConcurrency.all)(tasks);
      this.timespans = [...timespans.filter(timespan => timespan.offer.id === this.offerId).toArray()];
    }
    if (timespanId) {
      this.activeTimespan = this.timespans.find(timespan => timespan.id === timespanId);
    }
    if (!this.activeTimespan) {
      var _this$timespans;
      this.activeTimespan = (_this$timespans = this.timespans) === null || _this$timespans === void 0 ? void 0 : _this$timespans.firstObject;
    }
    this.isPricePerRoom = !((_this$activeTimespan = this.activeTimespan) !== null && _this$activeTimespan !== void 0 && _this$activeTimespan.amount);
    this.isPricePerRoom && this.fetchTimespanRooms.perform();
  }), _dec6 = (0, _emberConcurrency.task)(function* () {
    this.store.unloadAll('timespan-room');
    const tasks = [this.store.findAll('timespan-room', this.adapterOptions), (0, _emberConcurrency.timeout)(400)];
    const [activeTimespanRooms] = yield (0, _emberConcurrency.all)(tasks);
    this.activeTimespanRooms = activeTimespanRooms;
  }), (_class = class InstanceContentsOffersIndexOfferPricesController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor3, this);
      _initializerDefineProperty(this, "currentUser", _descriptor4, this);
      _defineProperty(this, "queryParams", ['create']);
      _initializerDefineProperty(this, "create", _descriptor5, this);
      _initializerDefineProperty(this, "offerId", _descriptor6, this);
      _initializerDefineProperty(this, "timespans", _descriptor7, this);
      _initializerDefineProperty(this, "activeTimespanRooms", _descriptor8, this);
      _initializerDefineProperty(this, "activeTimespan", _descriptor9, this);
      _initializerDefineProperty(this, "activeTimespanRoom", _descriptor10, this);
      _initializerDefineProperty(this, "isNewTimespanDialog", _descriptor11, this);
      _initializerDefineProperty(this, "isEditTimespanRoomDialog", _descriptor12, this);
      _initializerDefineProperty(this, "isEditTimespanDialog", _descriptor13, this);
      _initializerDefineProperty(this, "isPricePerRoom", _descriptor14, this);
      _initializerDefineProperty(this, "isLoading", _descriptor15, this);
      _initializerDefineProperty(this, "_ha", _descriptor16, this);
      _initializerDefineProperty(this, "onPriceSwitch", _descriptor17, this);
      _initializerDefineProperty(this, "deleteTimespanTask", _descriptor18, this);
      _initializerDefineProperty(this, "fetchTimespans", _descriptor19, this);
      _initializerDefineProperty(this, "fetchTimespanRooms", _descriptor20, this);
    }
    get adapterOptions() {
      var _this$activeTimespan2;
      return {
        adapterOptions: {
          offer: this.offerId,
          timespan: (_this$activeTimespan2 = this.activeTimespan) === null || _this$activeTimespan2 === void 0 ? void 0 : _this$activeTimespan2.id
        },
        reload: true
      };
    }
    get isViewer() {
      return this.currentUser.hasReadOnlyContents;
    }
    onSelectChange(timespan) {
      if (this.activeTimespan.id !== timespan.id) {
        this.activeTimespan = timespan;
        this.isPricePerRoom = !timespan.amount;
        this.fetchTimespanRooms.perform();
      }
    }
    onPriceEditClick(row) {
      var _this$activeTimespanR;
      const id = row[2];
      const timespanRoom = (_this$activeTimespanR = this.activeTimespanRooms) === null || _this$activeTimespanR === void 0 ? void 0 : _this$activeTimespanR.find(room => room.id === id);
      this.activeTimespanRoom = timespanRoom;
      this.isEditTimespanRoomDialog = true;
    }
    deleteTimespan(timespan) {
      this.uiDialog.showConfirm(this.intl.t('contents.offers.offer.timespan.deleteDialog.title'), (0, _template.htmlSafe)(this.intl.t('contents.offers.offer.timespan.deleteDialog.description', {
        timespan: timespan.name
      })), () => this.deleteTimespanTask.perform(timespan), this.intl.t('global.actions.delete'), true, true);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "create", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "offerId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "timespans", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "activeTimespanRooms", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "activeTimespan", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "activeTimespanRoom", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isNewTimespanDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "isEditTimespanRoomDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "isEditTimespanDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "isPricePerRoom", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "_ha", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onSelectChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onSelectChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onPriceEditClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onPriceEditClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteTimespan", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "deleteTimespan"), _class.prototype), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "onPriceSwitch", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "deleteTimespanTask", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "fetchTimespans", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "fetchTimespanRooms", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
});