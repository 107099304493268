define("additive-content/components/ac-contents/calendar-list/selects", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "ember-arg-types", "prop-types", "date-fns"], function (_exports, _component, _templateFactory, _component2, _object, _emberArgTypes, _propTypes, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="ac-calendar-list__select dib relative">
    <select
      id="calendar-months"
      class="bg-transparent font-lg-sm font-md primary cpointer pl05 pr2"
      onchange={{this.onChangeMonth}}
    >
      {{#each this.months as |month|}}
        <option
          value={{month}}
          selected={{equal
            (date-format month format="MMMM")
            (date-format this.selectedDate format="MMMM")
          }}
        >
          {{date-format month format="MMMM"}}
        </option>
      {{/each}}
    </select>
  
    {{svg-jar "caret" class="absolute right-0 top-0 bottom-0 primary"}}
  </div>
  <div class="ac-calendar-list__select dib relative ml1">
    <select
      id="calendar-years"
      class="bg-transparent font-lg-sm font-md primary cpointer pl05 pr3"
      onchange={{this.onChangeYear}}
    >
      {{#each this.years as |year|}}
        <option value={{year}} selected={{equal year (date-format this.selectedDate format="yyyy")}}>
          {{year}}
        </option>
      {{/each}}
    </select>
  
    {{svg-jar "caret" class="absolute right-0 top-0 bottom-0 primary"}}
  </div>
  */
  {
    "id": "oPFAntYY",
    "block": "[[[10,0],[14,0,\"ac-calendar-list__select dib relative\"],[12],[1,\"\\n  \"],[10,\"select\"],[14,1,\"calendar-months\"],[14,0,\"bg-transparent font-lg-sm font-md primary cpointer pl05 pr2\"],[15,\"onchange\",[30,0,[\"onChangeMonth\"]]],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"months\"]]],null]],null],null,[[[1,\"      \"],[10,\"option\"],[15,2,[30,1]],[15,\"selected\",[28,[37,2],[[28,[37,3],[[30,1]],[[\"format\"],[\"MMMM\"]]],[28,[37,3],[[30,0,[\"selectedDate\"]]],[[\"format\"],[\"MMMM\"]]]],null]],[12],[1,\"\\n        \"],[1,[28,[35,3],[[30,1]],[[\"format\"],[\"MMMM\"]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\\n  \"],[1,[28,[35,4],[\"caret\"],[[\"class\"],[\"absolute right-0 top-0 bottom-0 primary\"]]]],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"ac-calendar-list__select dib relative ml1\"],[12],[1,\"\\n  \"],[10,\"select\"],[14,1,\"calendar-years\"],[14,0,\"bg-transparent font-lg-sm font-md primary cpointer pl05 pr3\"],[15,\"onchange\",[30,0,[\"onChangeYear\"]]],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"years\"]]],null]],null],null,[[[1,\"      \"],[10,\"option\"],[15,2,[30,2]],[15,\"selected\",[28,[37,2],[[30,2],[28,[37,3],[[30,0,[\"selectedDate\"]]],[[\"format\"],[\"yyyy\"]]]],null]],[12],[1,\"\\n        \"],[1,[30,2]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n\\n  \"],[1,[28,[35,4],[\"caret\"],[[\"class\"],[\"absolute right-0 top-0 bottom-0 primary\"]]]],[1,\"\\n\"],[13]],[\"month\",\"year\"],false,[\"each\",\"-track-array\",\"equal\",\"date-format\",\"svg-jar\"]]",
    "moduleName": "additive-content/components/ac-contents/calendar-list/selects.hbs",
    "isStrictMode": false
  });
  let AcContentsCalendarListSelectsComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.date), _dec2 = (0, _emberArgTypes.arg)(_propTypes.func), (_class = class AcContentsCalendarListSelectsComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "selectedDate", _descriptor, this);
      _initializerDefineProperty(this, "onChange", _descriptor2, this);
    }
    onChangeYear(e) {
      const date = (0, _dateFns.setYear)(this.selectedDate, e.target.value);
      this.onChange(date);
    }
    onChangeMonth(e) {
      const month = (0, _dateFns.getMonth)(new Date(e.target.value));
      const date = (0, _dateFns.setMonth)(this.selectedDate, month);
      this.onChange(date);
    }
    get months() {
      return (0, _dateFns.eachMonthOfInterval)({
        start: new Date(2020, 0, 1),
        end: new Date(2020, 11, 1)
      });
    }
    get years() {
      const yearRange = 10;
      const currentYear = (0, _dateFns.getYear)(new Date());
      const maxYear = currentYear + yearRange / 2;
      const minYear = currentYear - yearRange / 2;
      const range = maxYear - minYear + 1;
      return Array(...Array(range)).map((_, i) => `${i + minYear}`);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "selectedDate", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "onChange", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onChangeYear", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeYear"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeMonth", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeMonth"), _class.prototype)), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AcContentsCalendarListSelectsComponent);
});