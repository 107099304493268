define("additive-content/transitions/tab-transition", ["exports", "liquid-fire"], function (_exports, _liquidFire) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default( /* arg1, arg2 */
  ) {
    // Stop any currently running animation on oldElement
    (0, _liquidFire.stop)(this.oldElement);

    // Fade out the old element
    return (0, _liquidFire.animate)(this.oldElement, {
      opacity: 0,
      translateY: '40px'
    }, {
      duration: 200
    })
    // And then fade in the new element, from opacity 0 to 1
    .then(() => {
      (0, _liquidFire.animate)(this.newElement, {
        opacity: [1, 0],
        translateY: ['0px', '40px']
      }, {
        duration: 200
      });
    });
  }
});