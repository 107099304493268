define("additive-content/routes/instance/contents/points-of-interest/index/point-of-interest", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let InstanceContentsPointsOfInterestIndexPointOfInterestRoute = _exports.default = (_class = class InstanceContentsPointsOfInterestIndexPointOfInterestRoute extends _route.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor, this);
    }
    renderTemplate() {
      this.render('instance/contents/points-of-interest/index/point-of-interest', {
        into: 'instance/contents/points-of-interest',
        outlet: 'detail'
      });
    }
    model(params) {
      return this.store.findRecord('point-of-interest', params.id);
    }
    setupController(controller, model) {
      super.setupController(...arguments);
      model.setAllInterestTags();
    }
    resetController(controller, isExiting) {
      if (isExiting) {
        var _controller$model, _controller$model2;
        this.uiAppSettings.setLocale(this.uiAppSettings.languages.defaultLanguage);
        return !((_controller$model = controller.model) !== null && _controller$model !== void 0 && _controller$model.get('isDestroyed')) && !((_controller$model2 = controller.model) !== null && _controller$model2 !== void 0 && _controller$model2.get('isDeleted')) && controller.model.reload();
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});