define("additive-content/components/ac-contents/detail-navbar", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-arg-types", "prop-types", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _component2, _emberArgTypes, _propTypes, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <UiNavigationDrawer
    @navDrawerStateKey="detail"
    @isRight={{true}}
    @isPermanent={{false}}
    ...attributes as |navdrawer|
  >
    <navdrawer.navbar @onClose={{this.onClose}} />
    <navdrawer.content>
      <div class="flex flex-column">
        <div class="font-lg primary">
          {{t "contents.common.information"}}
        </div>
        <div class="font-sm secondary">
          {{this.model.identifier}}
        </div>
      </div>
  
      {{#if this.model.tags.length}}
        <div class="flex flex-column pb2 pt4 pb3-sm">
          <div class="font-sm secondary">
            {{t "contents.common.properties.tags"}}
          </div>
          <UiTags @tags={{this.model.tags}} @isReadOnly={{true}} />
        </div>
      {{/if}}
    </navdrawer.content>
  </UiNavigationDrawer>
  */
  {
    "id": "M4S85k3j",
    "block": "[[[8,[39,0],[[17,1]],[[\"@navDrawerStateKey\",\"@isRight\",\"@isPermanent\"],[\"detail\",true,false]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,2,[\"navbar\"]],null,[[\"@onClose\"],[[30,0,[\"onClose\"]]]],null],[1,\"\\n  \"],[8,[30,2,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"flex flex-column\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"font-lg primary\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[\"contents.common.information\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"font-sm secondary\"],[12],[1,\"\\n        \"],[1,[30,0,[\"model\",\"identifier\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"model\",\"tags\",\"length\"]],[[[1,\"      \"],[10,0],[14,0,\"flex flex-column pb2 pt4 pb3-sm\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"font-sm secondary\"],[12],[1,\"\\n          \"],[1,[28,[35,1],[\"contents.common.properties.tags\"],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[8,[39,3],null,[[\"@tags\",\"@isReadOnly\"],[[30,0,[\"model\",\"tags\"]],true]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[2]]]]]],[\"&attrs\",\"navdrawer\"],false,[\"ui-navigation-drawer\",\"t\",\"if\",\"ui-tags\"]]",
    "moduleName": "additive-content/components/ac-contents/detail-navbar.hbs",
    "isStrictMode": false
  });
  /**
   * Detail navbar for an order object.
   *
   * Currently it allows adding and editing a notice to an order.
   *
   *
   * @class detail-navbar
   * @module ac-contents
   */
  let AcContentsDetailNavbarComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.func), (_class = class AcContentsDetailNavbarComponent extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * the current model
       *
       * @argument model
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "model", _descriptor, this);
      /**
       * Clousure that closes the right sidenav
       * @function onClose
       */
      _initializerDefineProperty(this, "onClose", _descriptor2, this);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "model", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  })), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AcContentsDetailNavbarComponent);
});