define("additive-content/controllers/instance/landing-pages", ["exports", "@ember/controller", "@ember/service", "@glimmer/tracking", "@additive-apps/ui/constants", "@additive-apps/utils/utils/plans"], function (_exports, _controller, _service, _tracking, _constants, _plans) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let InstanceLandingPagesController = _exports.default = (_class = class InstanceLandingPagesController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      /**
       * whether the create dialog is shown
       *
       * @property isCreateDialog
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isCreateDialog", _descriptor4, this);
    }
    get hasCreateButton() {
      var _this$currentUser$cur, _this$uiAppSettings, _marketingAutomationA;
      if (this.currentUser.hasReadOnlyLPsAndWidgets) {
        return false;
      }
      const marketingAutomationApp = (_this$currentUser$cur = this.currentUser.currentOrganization.accessibleApps) === null || _this$currentUser$cur === void 0 ? void 0 : _this$currentUser$cur.find(app => app.id === _constants.APP_ID_MARKETING_CLOUD);
      const hasLandingPageModule = !!((_this$uiAppSettings = this.uiAppSettings) !== null && _this$uiAppSettings !== void 0 && (_this$uiAppSettings = _this$uiAppSettings.modules) !== null && _this$uiAppSettings !== void 0 && _this$uiAppSettings.find(element => element === 'landing-page'));
      const marketingAutomationAppPlanName = marketingAutomationApp === null || marketingAutomationApp === void 0 || (_marketingAutomationA = marketingAutomationApp.planName) === null || _marketingAutomationA === void 0 ? void 0 : _marketingAutomationA.slice(0, marketingAutomationApp === null || marketingAutomationApp === void 0 ? void 0 : marketingAutomationApp.planName.indexOf('_'));
      return marketingAutomationApp && (0, _plans.gte)(marketingAutomationAppPlanName, _plans.PLAN_PROFESSIONAL) || hasLandingPageModule;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isCreateDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  })), _class);
});